import Select from "../components/Select";
import { stateFilterData$, filterValueLabel$, useRootState } from "../state.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";

import { ChevronDown } from "../components/Icons.js";

function getApplicationNode() {
  return document.getElementById("root");
}

function FilterModal() {
  const [root, actions] = useRootState();
  const stateFilterData = useRecoilValue(stateFilterData$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);

  return (
    <div className="h-full w-full rounded-md bg-white shadow-lg p-6 overflow-y-scroll">
      {_.map(stateFilterData, (val, index) => {
        const label = _.get(val, "label");
        const filterValue = _.get(val, "value");
        return (
          <div
            className="w-11/12 p-3 text-xl mx-auto hover:bg-ultralow rounded-lg"
            key={label}
          >
            <input
              type="radio"
              className="hidden"
              id={filterValue}
              onChange={(e) => {
                e.target.checked && actions.setFilterValue(filterValue);
              }}
              checked={_.get(filterValueLabel, "value") === filterValue}
            />
            <label
              htmlFor={filterValue}
              className="font-medium text-high text-lg flex items-center"
            >
              <span className="w-5 h-5 inline-block mr-2 rounded-full border-primary-green flex-no-shrink border-2"></span>
              {label}
            </label>
          </div>
        );
      })}
    </div>
  );
}

export default FilterModal;
