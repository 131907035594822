import { useState, useCallback, useLayoutEffect } from "react";

function getDimensionObject(node: HTMLElement): DimensionObject {
    const rect = node.getBoundingClientRect();
    const top  = rect.top;
    const left = rect.left

    return {
        width: rect.width,
        height: rect.height,
        top: "x" in rect ? rect.x : top,
        left: "y" in rect ? rect.y : left,
        x: "x" in rect ? rect.x : left,
        y: "y" in rect ? rect.y : top,
        right: rect.right,
        bottom: rect.bottom
    };
}

function useDimensions({
    liveMeasure = true
}: UseDimensionsArgs = {}): UseDimensionsHook {
    const [dimensions, setDimensions] = useState({});
    const [node, setNode] = useState(null);

    const ref = useCallback(node => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () =>
                window.requestAnimationFrame(() =>
                    setDimensions(getDimensionObject(node!))
                );
            measure();

            if (liveMeasure) {
                window.addEventListener("resize", measure);
                window.addEventListener("scroll", measure);

                return () => {
                    window.removeEventListener("resize", measure);
                    window.removeEventListener("scroll", measure);
                };
            }
        }
    }, [liveMeasure, node]);

    return [ref, dimensions, node];
}

export default useDimensions;

export interface DimensionObject {
    width: number;
    height: number;
    top: number;
    left: number;
    x: number;
    y: number;
    right: number;
    bottom: number;
}

export type UseDimensionsHook = [
    (node: HTMLElement) => void,
    {} | DimensionObject,
    HTMLElement | null,
];

export interface UseDimensionsArgs {
    liveMeasure?: boolean;
}