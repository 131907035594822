export const STATES_BBOXES = {
  ME: [-71.084334, 42.977764, -66.949895, 47.459686],
  MA: [-73.508142, 41.237964, -69.928261, 42.886589],
  MI: [-90.418136, 41.696089, -82.412965, 48.190975],
  MT: [-116.050003, 44.358221, -104.039563, 49.00139],
  NV: [-120.005746, 35.002085, -114.039648, 42.002207],
  NJ: [-75.559614, 38.928519, -73.893979, 41.357423],
  NY: [-79.762152, 40.496103, -71.856214, 45.01585],
  NC: [-84.321869, 33.840324, -75.458659, 36.588117],
  OH: [-84.820159, 38.403202, -80.518693, 41.977523],
  PA: [-80.519891, 39.7198, -74.689516, 42.26986],
  RI: [-71.862772, 41.146339, -71.12057, 42.018798],
  TN: [-90.310298, 34.982972, -81.6469, 36.678118],
  TX: [-106.645646, 25.837377, -93.516407, 36.500704],
  UT: [-114.052962, 36.997968, -109.041058, 42.001567],
  WA: [-124.733174, 45.543541, -116.915989, 49.002494],
  WI: [-92.888114, 42.49192, -86.805415, 47.080006],
  PR: [-67.940799, 17.884813, -65.22111, 18.515757],
  MD: [-79.487651, 37.911717, -75.048939, 39.723043],
  AL: [-88.473227, 30.221132, -84.890894, 35.008028],
  AK: [-188.90491, 51.61274, -129.986323, 71.351633],
  AZ: [-114.813991, 31.332177, -109.045223, 37.00426],
  AR: [-94.617919, 33.004106, -89.644838, 36.4996],
  CA: [-124.409591, 32.534156, -114.134427, 42.009518],
  CO: [-109.060253, 36.992426, -102.041524, 41.003444],
  CT: [-73.727775, 40.985171, -71.786994, 42.050587],
  DE: [-75.788658, 38.451013, -75.048939, 39.839007],
  DC: [-77.1199, 38.791513, -76.909395, 38.99511],
  FL: [-87.634938, 24.544701, -80.031362, 31.000888],
  GA: [-85.605165, 30.357851, -80.840549, 35.000771],
  HI: [-159.764448, 18.948267, -154.807817, 22.228955],
  ID: [-117.243027, 41.988057, -111.043564, 49.001146],
  IL: [-91.513079, 36.970298, -87.496494, 42.508302],
  IN: [-88.058499, 37.771742, -84.784579, 41.760592],
  IA: [-96.639704, 40.375501, -90.140061, 43.501196],
  KS: [-102.051744, 36.993083, -94.589933, 40.003162],
  KY: [-89.571509, 36.497129, -81.964971, 39.147458],
  LA: [-94.043147, 28.925011, -88.817017, 33.019457],
  MN: [-97.239209, 43.499356, -89.489226, 49.384358],
  MS: [-91.655009, 30.173943, -88.097888, 34.996052],
  MO: [-95.774704, 35.995683, -89.098843, 40.61364],
  NE: [-104.053514, 39.999998, -95.30829, 43.001708],
  NH: [-72.557247, 42.69699, -70.610634, 45.305476],
  NM: [-109.050173, 31.332301, -103.001964, 37.000232],
  ND: [-104.0489, 45.935054, -96.554507, 49.000687],
  OK: [-103.002565, 33.616567, -94.431215, 37.002206],
  OR: [-124.565973, 41.991794, -116.463504, 46.271004],
  SC: [-83.353238, 32.0346, -78.541087, 35.215402],
  SD: [-104.057698, 42.479635, -96.436589, 45.94545],
  VT: [-73.43774, 42.726853, -71.464555, 45.016659],
  VA: [-83.675413, 36.540738, -75.242266, 39.466012],
  WV: [-82.644739, 37.201483, -77.719029, 40.638801],
  WY: [-111.056888, 40.994746, -104.05216, 45.005904],
};

export const MAINLAND_BBOX = [-124.733174, 24.544701, -66.949895, 49.384358];
