import { useRootState, isDimensionsModalOpen$ } from "../state.js";
import { useRecoilValue } from "recoil";

import StateRank from "./StateRank.js";

function getApplicationNode() {
  return document.getElementById("root");
}

function StatesModal() {
  return (
    <div className="h-full w-full rounded-md bg-white shadow-lg py-6 overflow-y-scroll">
      <StateRank />
    </div>
  );
}

export default StatesModal;
