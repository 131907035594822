import { Suspense } from "react";
import { useRecoilValue } from "recoil";
import {
  hasComparingState$,
  isStateView$,
  isStateRankView$,
} from "../state.js";
import Factors from "../components/Factors.js";
import Dimensions from "../components/Dimensions.js";
import FilterState from "../components/FilterState";
import FilterCountry from "../components/FilterCountry";
import StateHeader from "../components/StateHeader.js";
import CountryHeader from "../components/CountryHeader.js";
import StateRank from "../components/StateRank.js";

import ComparePanel from "./ComparePanel.js";

function InnerSidebar() {
  const isStateView = useRecoilValue(isStateView$);
  const isStateRankView = useRecoilValue(isStateRankView$);
  const hasComparingState = useRecoilValue(hasComparingState$);

  return (
    <div>
      {isStateView && hasComparingState ? (
        <div className="mb-4">
          <ComparePanel />
        </div>
      ) : null}

      <div className="sidebar mb-6 pt-4 pb-4 bg-superlow">
        {isStateView ? <StateHeader /> : <CountryHeader />}
      </div>

      <div className="sidebar relative z-50 mb-3">
        {isStateView ? <FilterState /> : <FilterCountry />}
      </div>

      <div className="sidebar relative z-1">
        {isStateRankView ? (
          <StateRank />
        ) : (
          <>
            <Dimensions />
            <hr style={{margin: "20px -30px", borderColor: "#e7ecf0"}}/>
            <Factors />
          </>
        )}
      </div>
    </div>
  );
}

function Sidebar() {
  return (
  <Suspense fallback={""}>
    <InnerSidebar />
  </Suspense>
  );
}

export default Sidebar;
