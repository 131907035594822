import MapGl, {
  WebMercatorViewport,
  FlyToInterpolator,
  Source,
  Layer,
  Marker,
  NavigationControl,
} from "react-map-gl";
import {
  boundingBox$,
  isSidebarOpen$,
  isStateView$,
  filterType$,
  stateCountyFeatureIds$,
  colorScaleToFeatureIds$,
  stateSelectedCountyFeatureIds$,
  selectedStateFeatureIds$,
  mapPopupData$,
  msaMapData$,
  statesFeatureIds$,
  useRootState,
  stateFilterCountiesData$,
  FIPS_TO_STATE,
  isCountryView$
} from "../state.js";
import { useRecoilValue } from "recoil";
import { useState, useEffect, useRef, Suspense } from "react";
import { easeCubic } from "d3-ease";
import _ from "lodash";
import bezier from "bezier-easing";
import distance from "@turf/distance";
import { useAnalytics } from "../analytics/analytics.js";
import { stateCodeToName } from "../domain/states.js";
import { ColorContrastCalc } from "color-contrast-calc";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2hhcmVjYXJlIiwiYSI6ImNrOW1tNzF1YTA5Ym4zbW9xaWU0MnppdXcifQ.avIIGIUxlm7fSbmzPujXHg";

const sidebarTransition = bezier(0.42, 0, 0.58, 1);

function logFeatures(map, e) {
  var features = map.queryRenderedFeatures(e.point);

  // Limit the number of properties we're displaying for
  // legibility and performance
  var displayProperties = [
    "type",
    "properties",
    "id",
    "layer",
    "source",
    "sourceLayer",
    "state",
  ];

  var displayFeatures = features.map(function (feat) {
    var displayFeat = {};
    displayProperties.forEach(function (prop) {
      displayFeat[prop] = feat[prop];
    });
    return displayFeat;
  });

  console.log(
    "**********DISPLAY FEATURES************",
    JSON.stringify(displayFeatures, null, "\t")
  );
}

function getClickedCountyFeatureId(map, e) {
  let features = map.queryRenderedFeatures(e.point, {
    layers: ["highlighted-counties-fill"],
  });
  return _.get(features, [0, "id"]);
}

function clamp(val, min, max) {
  return Math.min(Math.max(min, val), max);
}

const PADDING = {
  open: { top: 40, left: 40, right: 600, bottom: 40 },
  closed: { top: 40, left: 40, right: 64, bottom: 40 },
  mobile: { top: 80, left: 40, right: 40, bottom: 40 },
};

function getNewViewport(
  viewport,
  mode,
  boundingBox,
  isSidebarOpen,
  isStateView
) {
  const { width, height } = viewport;
  const [minX, minY, maxX, maxY] = boundingBox;
  let padding =
    mode === "desktop"
      ? isSidebarOpen
        ? PADDING.open
        : PADDING.closed
      : PADDING.mobile;

  if (mode === "desktop" && !isStateView) {
    padding.bottom = 200;
  }

  return new WebMercatorViewport({ width, height }).fitBounds(
    [
      [minX, minY],
      [maxX, maxY],
    ],
    {
      padding,
    }
  );
}

function getRankColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090E11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
      ? dark.hexCode
      : light.hexCode;
}

function FeaturePopup({ state, name, score, rank, onClick, color, dataLabel, areaCount }) {
  const isCountryView = useRecoilValue(isCountryView$);
  areaCount = isCountryView ? 50 : areaCount;
  return (
    <div
      className={`selected-feature-marker-wrap speech bottom bg-surface p-5 rounded-25 ${ 
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      <div className="inner-selected-feature-marker-wrap relative flex flex-col">
        <div className="text-full text-high font-bold mb-3">{name}</div>
        <div className="text-lg text-medium">{dataLabel}</div>
        <div
            className="state-rank-wrap flex items-center justify-center font-medium text-full"
            style={{
              background: color,
              color: getRankColor(color),
              paddingTop: "5px",
              paddingBottom: "3px"
            }}
        >
          {score}
        </div>

        {rank && (<div className="text-lg text-high font-medium mt-2">
          {state} Ranking #{rank} of {areaCount}
        </div>)}
      </div>
    </div>
  );
}

function SelectedFeatureMarker() {
  const [root, actions] = useRootState();
  const mapPopupData = useRecoilValue(mapPopupData$);
  const counties = useRecoilValue(stateFilterCountiesData$);
  const { logClickEvent } = useAnalytics()
  const isCountryView = useRecoilValue(isCountryView$);

  if (!mapPopupData || !mapPopupData.name) return null;
  return (
    <Marker
      latitude={mapPopupData.latitude}
      longitude={mapPopupData.longitude}
      offsetLeft={-112}
      offsetTop={-145}
    >
      <div className={`relative ${isCountryView ? "country" : ""}`}>
        <FeaturePopup {...mapPopupData} onClick={() => {
          actions.commitMouseOverValue()

          if (root.view === 'country') {
            logClickEvent({
              state: stateCodeToName[FIPS_TO_STATE[_.get(root, 'country.mouseOver')]],
              county: null
            })
          } else if (_.get(root, 'defaultState.mouseOver')) {
       
            logClickEvent({
              county: _.get(counties.find((county) => county.value === _.get(root, 'defaultState.mouseOver')), 'label', '')
            })
          }

        }} />
      </div>
    </Marker>
  );
}

function InnerStateCountyDataLayer({ beforeId }) {
  const stateCountyFeatureIds = useRecoilValue(stateCountyFeatureIds$);
  const colorScaleToFeatureIds = useRecoilValue(colorScaleToFeatureIds$);
  const stateSelectedCountyFeatureIds = useRecoilValue(
    stateSelectedCountyFeatureIds$
  );

  return (
    <Source
      id="counties"
      type="vector"
      url="mapbox://mapbox.boundaries-adm2-v3"
    >
      <Layer
        source-layer="boundaries_admin_2"
        id="highlighted-states-fill"
        beforeId={beforeId}
        type="fill"
        filter={["in", ["id"], ["literal", []]]}
        paint={{
          "fill-outline-color": "#FFF",
        }}
      ></Layer>
      <Layer
        source-layer="boundaries_admin_2"
        id="highlighted-counties-fill"
        beforeId={beforeId}
        type="fill"
        filter={["in", ["id"], ["literal", stateCountyFeatureIds]]}
        paint={{
          "fill-outline-color": "#FFF",
          "fill-color": [
            "coalesce",
            [
              "get",
              ["concat", "id-", ["to-string", ["id"]]],
              ["literal", colorScaleToFeatureIds],
            ],
            "rgba(231,236,240,0.99)",
          ],
        }}
      ></Layer>
      <Layer
        source-layer="boundaries_admin_2"
        id="highlighted-counties-outline"
        beforeId={beforeId}
        type="line"
        filter={["in", ["id"], ["literal", stateCountyFeatureIds]]}
        paint={{
          "line-color": "#FFF",
          "line-width": 1,
        }}
      ></Layer>

      <Layer
        source-layer="boundaries_admin_2"
        id="selected-county-outline"
        beforeId={beforeId}
        type="line"
        filter={["in", ["id"], ["literal", stateSelectedCountyFeatureIds]]}
        layout={{
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-color": "#FFF",

          "line-width": 3,
        }}
      ></Layer>
    </Source>
  );
}

function StateCountyDataLayer({ beforeId }) {
  return (
    <Suspense fallback={null}>
      <InnerStateCountyDataLayer beforeId={beforeId} />
    </Suspense>
  );
}

function InnerCountryDataLayer({ beforeId }) {
  const statesFeatureIds = useRecoilValue(statesFeatureIds$);
  const colorScaleToFeatureIds = useRecoilValue(colorScaleToFeatureIds$);
  const selectedStateFeatureIds = useRecoilValue(selectedStateFeatureIds$);

  return (
    <Source id="states" type="vector" url="mapbox://mapbox.boundaries-adm1-v3">
      <Layer
        source-layer="boundaries_admin_1"
        id="highlighted-counties-fill"
        beforeId={beforeId}
        type="fill"
        filter={["in", ["id"], ["literal", []]]}
        paint={{
          "fill-outline-color": "#FFF",
        }}
      ></Layer>
      <Layer
        source-layer="boundaries_admin_1"
        id="highlighted-states-fill"
        beforeId={beforeId}
        type="fill"
        filter={["in", ["id"], ["literal", statesFeatureIds]]}
        paint={{
          "fill-color": [
            "coalesce",
            [
              "get",
              ["concat", "id-", ["to-string", ["id"]]],
              ["literal", colorScaleToFeatureIds],
            ],
            "transparent",
          ],
        }}
      ></Layer>
      <Layer
        source-layer="boundaries_admin_1"
        id="highlighted-states-outline"
        beforeId={beforeId}
        type="line"
        filter={["in", ["id"], ["literal", statesFeatureIds]]}
        paint={{
          "line-color": "#FFF",
          "line-width": 1,
        }}
      ></Layer>

      <Layer
        source-layer="boundaries_admin_1"
        id="selected-state-outline"
        beforeId={beforeId}
        type="line"
        filter={["in", ["id"], ["literal", selectedStateFeatureIds]]}
        layout={{
          "line-cap": "round",
          "line-join": "round",
        }}
        paint={{
          "line-color": "#FFF",

          "line-width": 3,
        }}
      ></Layer>
    </Source>
  );
}

function CountryDataLayer({ beforeId }) {
  return (
    <Suspense fallback={null}>
      <InnerCountryDataLayer beforeId={beforeId} />
    </Suspense>
  );
}

function MsaCircle({ colorScale, color }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 110 110">
      <circle
        cx="55"
        cy="55"
        r="46.75"
        fill={`url(#${colorScale}_radial)`}
        stroke={color}
        strokeLinejoin="round"
        strokeWidth=".5"
      />
      <circle
        cx="55"
        cy="55"
        r="54.75"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth=".5"
      />
      <circle
        cx="55"
        cy="55"
        r="13.75"
        fill="#fff"
        stroke={color}
        strokeWidth=".5"
      />
      <circle cx="55" cy="55" r="10" fill={color} />
      <defs>
        <radialGradient
          id="blue_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 47 -47 0 55 55)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".307" stopColor="#00B3FF" stopOpacity="0" />
          <stop offset=".745" stopColor="#00A3FF" stopOpacity=".2" />
          <stop offset="1" stopColor="#00A3FF" stopOpacity=".35" />
          <stop offset="1" stopColor="#0038FF" stopOpacity=".4" />
        </radialGradient>
        <radialGradient
          id="purple_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 47 -47 0 55 55)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".365" stopColor="#BF95ED" stopOpacity="0" />
          <stop offset=".818" stopColor="#A479D2" stopOpacity=".6" />
          <stop offset="1" stopColor="#C39EEC" />
        </radialGradient>
        <radialGradient
          id="lime_radial"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(0 47 -47 0 55 55)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".365" stopColor="#D4E157" stopOpacity="0" />
          <stop offset=".818" stopColor="#D4E157" stopOpacity=".6" />
          <stop offset="1" stopColor="#D4E157" />
        </radialGradient>
      </defs>
    </svg>
  );
}

function InnerStateMsaDataLayer() {
  const msaMapData = useRecoilValue(msaMapData$);
  const mapPopupData = useRecoilValue(mapPopupData$);
  const [root, actions] = useRootState();

  return (
    <>
      {_.map(
        _.sortBy(msaMapData, (m) => {
          return m === mapPopupData ? 1 : -1;
        }),
        (msa) => {
          return (
            <Marker
              latitude={msa.latitude}
              longitude={msa.longitude}
              offsetLeft={-55}
              offsetTop={-55}
              key={msa.name}
            >
              <div
                className="msa-marker-wrap cursor-pointer"
                onMouseEnter={(e) => actions.setMouseOverValue(msa.fips)}
                onMouseLeave={(e) => actions.setMouseOverValue(null)}
                onClick={(e) => actions.setFilterValue(msa.fips)}
              >
                <div className="w-full h-full">
                  <MsaCircle {...msa} />
                </div>
                  {mapPopupData === msa && (<FeaturePopup {...msa} />)}
              </div>
            </Marker>
          );
        }
      )}
    </>
  );
}

function StateMsaDataLayer() {
  return (
    <Suspense fallback={null}>
      <InnerStateMsaDataLayer />
    </Suspense>
  );
}

function ensureMinMobileZoom(mode, viewport) {
  if (mode === "mobile" && viewport.zoom < 2) {
    return { ...viewport, zoom: 2 };
  }
  return viewport;
}

function InnerMap({ mode }) {
  const boundingBox = useRecoilValue(boundingBox$);
  const isSidebarOpen = useRecoilValue(isSidebarOpen$);
  const filterType = useRecoilValue(filterType$);
  const isStateView = useRecoilValue(isStateView$);

  const [isLoaded, setIsLoaded] = useState(false);
  const [isInitialBBoxSet, setIsInitialBBoxSet] = useState(false);
  const [firstSymbolLayerId, setFirstSymbolLayerId] = useState(null);
  const lastBoundingBoxRef = useRef(null);
  const lastIsSidebarOpenRef = useRef(isSidebarOpen);
  const [root, actions] = useRootState();
  const mapRef = useRef(null);
  const {innerWidth: width, innerHeight: height} = window

  const [viewport, setViewport] = useState({
    latitude: 0,
    longitude: 0,
    zoom: 14,
    bearing: 0,
    pitch: 0,
    width,
    height
  });

  useEffect(() => {
    if (isLoaded && boundingBox && !isInitialBBoxSet) {
      const newViewport = getNewViewport(
        viewport,
        mode,
        boundingBox,
        isSidebarOpen,
        isStateView
      );
      lastBoundingBoxRef.current = boundingBox;
      setViewport(ensureMinMobileZoom(mode, { ...viewport, ...newViewport }));
      setIsInitialBBoxSet(true);
    }
  }, [
    isLoaded,
    isInitialBBoxSet,
    viewport,
    boundingBox,
    isSidebarOpen,
    mode,
    isStateView,
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      boundingBox &&
      isInitialBBoxSet &&
      boundingBox !== lastBoundingBoxRef.current
    ) {
      const newViewport = getNewViewport(
        viewport,
        mode,
        boundingBox,
        isSidebarOpen,
        isStateView
      );
      const dist = distance(
        [viewport.longitude, viewport.latitude],
        [newViewport.longitude, newViewport.latitude]
      );

      setViewport(
        ensureMinMobileZoom(mode, {
          ...viewport,
          ...newViewport,
          transitionDuration: clamp(dist, 300, 2000),
          transitionInterPolator: new FlyToInterpolator(),
          transitionEasing: easeCubic,
        })
      );
      lastBoundingBoxRef.current = boundingBox;
    }
  }, [
    isLoaded,
    isInitialBBoxSet,
    viewport,
    boundingBox,
    isSidebarOpen,
    mode,
    isStateView,
  ]);

  useEffect(() => {
    if (
      isLoaded &&
      isInitialBBoxSet &&
      isSidebarOpen !== lastIsSidebarOpenRef.current
    ) {
      const newViewport = getNewViewport(
        viewport,
        mode,
        lastBoundingBoxRef.current,
        isSidebarOpen,
        isStateView
      );

      setViewport(
        ensureMinMobileZoom(mode, {
          ...viewport,
          ...newViewport,
          transitionDuration: 500,
          transitionInterPolator: new FlyToInterpolator(),
          transitionEasing: sidebarTransition,
        })
      );
      lastIsSidebarOpenRef.current = isSidebarOpen;
    }
  }, [
    isLoaded,
    isInitialBBoxSet,
    viewport,
    boundingBox,
    isSidebarOpen,
    mode,
    isStateView,
  ]);

  return (
    <div
      className={`transition-all delay-300 duration-500 ease-out transform h-full w-full ${
        isLoaded && isInitialBBoxSet ? "" : "opacity-0 scale-150"
      }`}
    >
      <MapGl
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={(nextViewport) => setViewport(nextViewport)}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        mapStyle="mapbox://styles/sharecare/ckiujqxkk2rib19qjw44czxpa"
        onLoad={(e) => {
          const map = e.target;
          const layers = map.getStyle().layers;
          for (let i = 0; i < layers.length; i++) {
            let current = layers[i];
            if (
              current.type === "symbol" &&
              current.id !== firstSymbolLayerId
            ) {
              setFirstSymbolLayerId(current.id);
              break;
            }
          }

          setIsLoaded(true);
        }}
        ref={mapRef}
        interactiveLayerIds={
          isStateView && "county" === filterType
            ? ["highlighted-counties-fill"]
            : !isStateView
            ? ["highlighted-states-fill"]
            : []
        }
        onClick={(e) => {
          if ((isStateView && "county" === filterType) || !isStateView) {
            // Hacky way to check if we have the data for the feature. We grab
            // the opacity of the color, and check if it's less than 1. In the layer
            // paint style, opacity is set to 0.99 if data is missing
            const featurePaintOpacity = _.get(e, [
              "features",
              0,
              "layer",
              "paint",
              "fill-color",
              "a",
            ]);

            !(featurePaintOpacity < 1) &&
              actions.setFilterValueFromFeatureId(
                _.get(e, ["features", 0, "id"])
              );
          }
        }}
        onHover={(e) => {
          if (
            mode !== "mobile" &&
            ((isStateView && "county" === filterType) || !isStateView)
          ) {
            actions.setMouseOverValueFromFeatureId(
              _.get(e, ["features", 0, "id"])
            );
          }
        }}
        onMouseLeave={(e) => {
          actions.setMouseOverValueFromFeatureId(null);
        }}
      >
        {isStateView && "county" === filterType ? (
          <>
            <StateCountyDataLayer beforeId={firstSymbolLayerId} />
            <SelectedFeatureMarker />
          </>
        ) : isStateView && "msa" === filterType ? (
          <StateMsaDataLayer beforeId={firstSymbolLayerId} />
        ) : null}
        {!isStateView ? (
          <>
            <CountryDataLayer beforeId={firstSymbolLayerId} />
            <SelectedFeatureMarker />
          </>
        ) : null}
        {mode === "mobile" ? (
          <div
            style={{
              //TODO Fine adjustments
              position: "absolute",
              bottom: "8rem",
              right: "1rem",
            }}
          >
            <NavigationControl showCompass={false} />
          </div>
        ) : null}
      </MapGl>
    </div>
  );
}

function Map(props) {
  return (
    <Suspense fallback={""}>
      <InnerMap {...props} />
    </Suspense>
  );
}

export default Map;
