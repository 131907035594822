import { analyticsData$ } from "../state.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { isIos } from "../client/isIos";
import { isAndroid } from "../client/isAndroid";

const EVENT_NAMES = {
  PAGE_VIEW: "page_view",
  CLICK: "Cwbi_Click",
};

const FILTER_TYPES = {
  rankings: "Ranking",
  states: "States",
  county: "County",
  msa: "City/Metro",
  dimension: "Wellbeing Dimensions",
  factor: "Key Social Factors",
};

function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function getBaseData() {
  const isEmbedded = inIframe()
  
  return {
    "rg.platform": "Web",
    "rg.embedded":
      isEmbedded && isIos()
        ? "iOS"
        : isEmbedded && isAndroid()
        ? "Android"
        : "Web",
    "rg.market": "unauthenticated",
    "rg.sitesection": "Community Well-Being",
  };
}

function log(name, data) {
  // Pass directly to analytics script.
  if (window._satellite && _.isFunction(window._satellite.track)) {
    window.xhrPayloadExport = { name, data: { ...data } };
    window._satellite.track(name, { ...data });
  }
}

function getMapInfo(state, city) {
  let str = "US";

  if (state) {
    str += "|" + state;
  }

  if (city) {
    str += "|" + city;
  }

  return str;
}

function logPageViewEvent(contentType, pageName) {
  const data = {
    ...getBaseData(),
    "rg.contenttype": contentType,
    "rg.pagename": pageName,
  };

  log(EVENT_NAMES.PAGE_VIEW, data);
}

function logClickEvent(filterType, filters, state, city) {
  const data = {
    ...getBaseData(),
    "rg.filtertype": FILTER_TYPES[filterType],
    "rg.filtervalue": filterType === "rankings" ? "ranking" : filters.join(","),
    "rg.mapinfo": getMapInfo(state, city),
  };

  log(EVENT_NAMES.CLICK, data);
}

function useAnalytics() {
  const data = useRecoilValue(analyticsData$);

  return {
    // It is necessary to override the state
    // that actually changed since the recoil
    // state may not be fully updated if this
    // is called right after a state action
    logClickEvent: (overrides) => {
      const { filterType, filters, state, county } = {
        ...data,
        ...overrides,
      };
      logClickEvent(filterType, filters, state, county);
    },
  };
}

export { logPageViewEvent, logClickEvent, useAnalytics };
