import { colorScale$ } from "../state.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";

function ColorScale() {
  const colorScale = useRecoilValue(colorScale$);
  return (
    <div className="flex flex-row w-full color-scale-wrap">
      {_.map(colorScale, (c) => {
        return (
          <div className="flex-grow color-scale-item" key={c.label}>
            <div
              style={{ background: c.color }}
              className="color-scale-cell transition-all duration-500 ease-out"
            />
            <div className="text-center text-base text-high font-medium">
              {c.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ColorScale;
