import AriaModal from "react-aria-modal";
import { Info, X } from "./Icons.js";
import { useState } from "react";
import _ from "lodash";
import { logPageViewEvent } from "../analytics/analytics.js";

function getApplicationNode() {
  return document.getElementById("root");
}

function Dimensions() {
  return (
    <div className="font-helvetica-neue text-high">
      <h3 className="text-1xl font-bold">Well-Being Dimensions Info</h3>
      <p className="text-lg mb-7">
        Consider the well-being opportunities for this community.
      </p>

      <h4 className="text-l font-bold">Physical</h4>
      <p className="text-lg mb-4">
        You have good health and enough energy to get things done daily.
      </p>

      <h4 className="text-l font-bold">Community</h4>
      <p className="text-lg mb-4">
        You like where you live, feeling safe and having pride in your
        community.
      </p>

      <h4 className="text-l font-bold">Purpose</h4>
      <p className="text-lg mb-4">
        You like what you do each day and are motivated to achieve your goals.
      </p>

      <h4 className="text-l font-bold">Social</h4>
      <p className="text-lg mb-4">
        You have supportive relationships and love in your life.
      </p>

      <h4 className="text-l font-bold">Financial</h4>
      <p className="text-lg mb-4">
        You manage your economic life to increase financial security and reduce
        stress.
      </p>

      <h4 className="text-l font-bold">Healthcare Access</h4>
      <p className="text-lg mb-4">
        Community members can find healthcare facilities or resources easily.
      </p>

      <h4 className="text-l font-bold">Food Access</h4>
      <p className="text-lg mb-4">
        Healthy food retailers or grocery stores are easily available.
      </p>

      <h4 className="text-l font-bold">Resource Access</h4>
      <p className="text-lg mb-4">
        Key community resources such as libraries, churches, and senior
        employment are easily available.
      </p>

      <h4 className="text-l font-bold">Economic Security</h4>
      <p className="text-lg mb-4">
        Community members are employed, insured, and otherwise stable
        financially.
      </p>

      <h4 className="text-l font-bold">Housing & Transportation</h4>
      <p className="text-lg">
        Housing values are stable, public transportation is supported, and more.
      </p>
    </div>
  );
}

function Factors() {
  return (
    <div className="text-high">
      <h3 className="text-xl font-bold">Key Social Factors Info</h3>
      <p className="text-lg mb-8">
        Isolate the social factors that impact this community’s well-being.
      </p>

      <h4 className="text-l font-bold">Access to Parks, Green Space</h4>
      <p className="text-lg mb-4">
        This assesses the number of parks per square mile.
      </p>

      <h4 className="text-l font-bold">Advanced Degrees </h4>
      <p className="text-lg mb-4">
        This calculates the percent of residents with a graduate or professional
        degree.
      </p>

      <h4 className="text-l font-bold">Availability of MDs</h4>
      <p className="text-lg mb-4">
        This assesses the number of non-federal medical doctors per 1000
        residents.
      </p>

      <h4 className="text-l font-bold">College Degrees </h4>
      <p className="text-lg mb-4">
        This tracks the percent of residents with a college degree.
      </p>

      <h4 className="text-l font-bold">Home Value 500k+ </h4>
      <p className="text-lg mb-4">
        This calculates the percent of homes valued at $500K or more.
      </p>

      <h4 className="text-l font-bold">Household Receiving SNAP </h4>
      <p className="text-lg mb-4">
        This factors in the percent of households receiving Supplemental
        Nutrition Assistance (SNAP Benefits).
      </p>

      <h4 className="text-l font-bold">Income Below Poverty</h4>
      <p className="text-lg mb-4">
        This tracks the percent of residents living in poverty.
      </p>

      <h4 className="text-l font-bold">Median Household Income</h4>
      <p className="text-lg mb-4">
        This evaluates the median household income.
      </p>

      <h4 className="text-l font-bold">Physical Inactivity</h4>
      <p className="text-lg mb-4">
        This tracks the percent of residents with no physical activity outside
        of work.
      </p>

      <h4 className="text-l font-bold">Voted in 2016 Election </h4>
      <p className="text-lg">
        This calculates the percent of residents who voted in the 2016
        presidential election.
      </p>
    </div>
  );
}

const INFO = {
  dimensions: { component: Dimensions, title: "Well-Being Dimensions Info" },
  factors: { title: "Key Social Factors Info", component: Factors },
};

function InfoPopup({ info }) {
  const [isOpen, setIsOpen] = useState(false);
  const InfoComponent = _.get(INFO, [info, "component"]);
  return (
    <div
      style={{ paddingTop: "2px" }}
      className="ml-1 text-medium hover:text-primary-green"
    >
      <button className="w-6 h-6" onClick={() => {
        setIsOpen(true)
        logPageViewEvent("Community Well-Being", _.get(INFO, [info, "title"]))
      }}>
        <Info />
      </button>
      <AriaModal
        onExit={() => setIsOpen(false)}
        mounted={isOpen}
        getApplicationNode={getApplicationNode}
        titleText={_.get(INFO, [info, "title"])}
        verticallyCenter={true}
      >
        <div className="info-modal bg-white rounded-10 mx-auto p-6 shadow-xl overflow-auto">
          <div className="flex justify-end -mt-3 -mr-3">
            <button
              className="text-primary-green w-8 h-8"
              onClick={() => setIsOpen(false)}
            >
              <X />
            </button>
          </div>
          <div className="-mt-5">
            {InfoComponent ? <InfoComponent /> : null}
          </div>
        </div>
      </AriaModal>
    </div>
  );
}

export default InfoPopup;
