import {
  useRootState,
  isSidebarOpen$,
  isStateView$,
  pipMapsData$,
  view$,
} from "./state.js";
import { useRecoilValue } from "recoil";
import { Suspense } from "react";
import Sidebar from "./desktop/Sidebar.js";
import Map from "./components/Map.js";
import StatePlacard from "./desktop/StatePlacard.js";
import CountryPlacard from "./desktop/CountryPlacard.js";
import ViewingFooter from "./desktop/ViewingFooter.js";
import CompareStateModal from "./desktop/CompareStateModal.js";
import HeaderButtons from "./desktop/HeaderButtons.js";
import PipMap from "./components/PipMap.js";
import _ from "lodash";

function ToggleSidebarButton() {
  const [rootState, actions] = useRootState();
  const isSidebarOpen = useRecoilValue(isSidebarOpen$);

  return (
    <button
      className={`toggle-sidebar-button absolute text-medium`}
      onClick={(e) => actions.toggleSidebar()}
    >
      <svg
        className={`w-full h-full transform transition-all duration-500 ease-in-out ${
          isSidebarOpen ? "" : "rotate-180"
        }`}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.21875 11.5469C4.75 12.7188 4.375 14.6875 4.375 15.9531C4.375 20.5469 7.04688 24.8594 11.5469 26.7344C17.4531 29.1719 24.25 26.3594 26.7344 20.4062C27.2031 19.2344 27.5781 17.2656 27.625 16C27.625 11.4062 24.9062 7.09375 20.4062 5.21875C14.5 2.78125 7.70312 5.59375 5.21875 11.5469ZM19.8438 6.625C23.3125 8.03125 26.125 12.25 26.125 15.9531C26.125 17.0781 25.75 18.8125 25.3281 19.8438C23.1719 25 17.2656 27.4844 12.1094 25.3281C8.64062 23.9219 5.875 19.7031 5.875 16C5.875 14.875 6.20312 13.1406 6.625 12.1094C8.78125 6.95313 14.6875 4.46875 19.8438 6.625ZM17.5 11.6875V20.2656C17.5 21.0156 18.3906 21.3906 18.9531 20.875L23.2188 16.6094C23.3594 16.4688 23.4531 16.1875 23.5 16V15.9531C23.5 15.7656 23.3594 15.4844 23.2188 15.3906L18.9531 11.0781C18.3906 10.5625 17.5 10.9375 17.5 11.6875ZM21.7188 16L19 18.7188V13.2344L21.7188 16ZM8.6875 15.4375C8.59375 15.5781 8.5 15.8125 8.5 16C8.5 16.1406 8.59375 16.375 8.6875 16.5156L13.1875 21.0156C13.3281 21.1094 13.5625 21.2031 13.7031 21.2031C14.125 21.2031 14.4531 20.875 14.4531 20.4531C14.4531 20.3125 14.3594 20.0781 14.2656 19.9375L10.2812 16L14.2656 12.0156C14.3594 11.875 14.4531 11.6406 14.4531 11.5C14.4531 11.0781 14.125 10.75 13.7031 10.75C13.5625 10.75 13.3281 10.8438 13.1875 10.9375L8.6875 15.4375Z"
          fill="currentColor"
        />
      </svg>
    </button>
  );
}

function PipMaps() {
  const pipMapsData = useRecoilValue(pipMapsData$);
  const [root, actions] = useRootState();
  const view = _.get(root, "view");
  return (
    <>
      {_.map(pipMapsData, (d) => {
        const action =
          "country" === view
            ? () => actions.selectStateFromCountryView(d.code)
            : "defaultState" === view
            ? () => actions.setView("comparingState")
            : () => actions.setView("defaultState");
        return (
          <PipMap
            mode="desktop"
            data={d}
            key={_.get(d, "name")}
            onClick={action}
          />
        );
      })}
    </>
  );
}

function DesktopApp() {
  const isSidebarOpen = useRecoilValue(isSidebarOpen$);
  const isStateView = useRecoilValue(isStateView$);

  return (
    <div
      className={`relative w-screen h-screen overflow-hidden font-helvetica-neue ${
        !isSidebarOpen ? "sidebar-closed" : ""
      }`}
    >
      <div className="absolute inset-0">
        <Map mode="desktop" />
      </div>
      <div className="desktop-pip-maps-wrap absolute flex">
        <Suspense fallback={null}>
          <PipMaps />
        </Suspense>
      </div>
      {isStateView ? (
        <div className="header-buttons-wrap absolute">
          <HeaderButtons />
        </div>
      ) : null}

      <div className="absolute viewing-footer-wrap transition-all duration-500 ease-in-out">
        <Suspense fallback={null}>
          <ViewingFooter />
        </Suspense>
      </div>

      <div className="state-placard-wrap absolute transition-all duration-500 ease-in-out">
        {isStateView ? <StatePlacard /> : <CountryPlacard />}
      </div>

      <div className="sidebar-wrap absolute top-0 right-0 bottom-0 transition-transform duration-500 ease-in-out bg-white sidebar-shadow">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fillRule="evenodd"
          strokeLinejoin="round"
          strokeMiterlimit="2"
          clipRule="evenodd"
          viewBox="0 0 33 90"
          className="sidebar-handle absolute"
        >
          <defs>
            <filter id="shadow" filterUnits="userSpaceOnUse">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="10"
                floodColor="black"
                floodOpacity="0.2"
              />
            </filter>
          </defs>
          <path
            fill="#fff"
            style={{ filter: "url(#shadow)" }}
            d="M20 44.897C20.052 27.432 32.973 25.258 32.973 0v89.998C32.973 64.693 20 62.559 20 45.002v-.105z"
          />
        </svg>
        <div className="inner-sidebar-wrap transition-all duration-500 ease-in-out w-full h-full overflow-auto">
          <Sidebar />
        </div>
        <ToggleSidebarButton />
      </div>
      <CompareStateModal />
    </div>
  );
}

export default DesktopApp;
