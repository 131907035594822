import { Suspense } from "react";
import StateHeader from "../components/StateHeader.js";

function InnerStatePlacard() {
  return (
    <div className="bg-white px-6 pb-4 state-placard rounded-10">
      <div className="mt-4">
        <StateHeader />
      </div>
    </div>
  );
}

function StatePlacard() {
  return (
    <Suspense fallback={""}>
      <InnerStatePlacard />
    </Suspense>
  );
}

export default StatePlacard;
