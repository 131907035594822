import { useRootState, isDimensionsModalOpen$ } from "../state.js";
import { useRecoilValue } from "recoil";

import Dimensions from "./Dimensions.js";

function getApplicationNode() {
  return document.getElementById("root");
}

function DimensionsModal() {
  return (
    <div className="h-full w-full rounded-md bg-white shadow-lg p-6">
      <Dimensions />
    </div>
  );
}

export default DimensionsModal;
