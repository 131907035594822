import Select from "../components/Select";
import {
  stateFilterData$,
  filterValueLabel$,
  filterType$,
  useRootState,
} from "../state.js";
import { stateLabelValues } from "../domain/states.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { useAnalytics } from "../analytics/analytics";

function FilterState() {
  const [root, actions] = useRootState();
  const stateFilterData = useRecoilValue(stateFilterData$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);
  const filterType = useRecoilValue(filterType$);

  const { logClickEvent } = useAnalytics();

  return (
    <div>
      <div className="flex flex-row items-center justify-center py-4">
        <div className="flex flex-row w-5/6 justify-around">
          <div className="w-1/2">
            <input
              type="radio"
              className="hidden"
              id="filter-states"
              onChange={(e) => {
                e.target.checked && actions.setFilterType("states");
                logClickEvent({ filterType: 'states' });
              }}
              checked={filterType === "states"}
            />
            <label
              htmlFor="filter-states"
              className="font-medium text-high text-lg flex items-center"
            >
              <span className="w-4 h-4 inline-block mr-2 rounded-full border-primary-green flex-no-shrink border-2"></span>
              States
            </label>
          </div>
          <div className="w-1/2">
            <input
              type="radio"
              className="hidden"
              id="filter-rankings"
              onChange={(e) => {
                e.target.checked && actions.setFilterType("rankings");
                logClickEvent({ filterType: 'rankings' });
              }}
              checked={filterType === "rankings"}
            />
            <label
              htmlFor="filter-rankings"
              className="font-medium text-high text-lg flex items-center"
            >
              <span className="w-4 h-4 inline-block mr-2 rounded-full border-primary-green flex-no-shrink border-2"></span>
              Rankings
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FilterState;
