import { useRecoilValue } from "recoil";
import {
  useRootState,
  hasComparingState$,
  hasDefaultState$,
  isFactorsModalOpen$,
  isDimensionsModalOpen$,
  isCountryView$,
  view$,
} from "../state.js";
import { ReactComponent as X } from "../components/icons/X.svg";
import { ReactComponent as CompareButton } from "../components/icons/CompareButton.svg";
import { ReactComponent as DimensionButton } from "../components/icons/DimensionButton.svg";
import { ReactComponent as FactorsButton } from "../components/icons/FactorsButton.svg";
import { ReactComponent as Home } from "../components/icons/Home.svg";
import { ReactComponent as UsMapButton } from "../components/icons/UsMapButton.svg";

function Button({ Icon, label, onClick, isActive }) {
  return (
    <button
      onClick={onClick}
      className="rounded-10 font-bold w-full w-full h-10 mx-1 text-medium hover:text-high flex flex-row shadow-lg bg-white"
    >
      <div
        className={`h-6 self-center mx-auto
      ${
        isActive ? "text-black " : onClick ? "text-primary-green" : "text-low"
      }`}
      >
        <Icon
            className={`${isActive ? "text-scale-1" : "text-low"}`}
        />
      </div>
    </button>
  );
}

function FooterButtons() {
  const [root, actions] = useRootState();
  const hasComparingState = useRecoilValue(hasComparingState$);
  const hasDefaultState = useRecoilValue(hasDefaultState$);
  const isFactorsModalOpen = useRecoilValue(isFactorsModalOpen$);
  const isDimensionsModalOpen = useRecoilValue(isDimensionsModalOpen$);
  const isCountryView = useRecoilValue(isCountryView$);

  return (
    <div className="flex justify-between items-center">
      <Button
        onClick={
          hasDefaultState
            ? actions.closeModalsAndSetDefaultStateView
            : actions.openCountryRankingView
        }
        label="Exit Compare View"
        Icon={Home}
        isActive={!(hasComparingState || isCountryView)}
      />
      <Button
        onClick={
          isDimensionsModalOpen
            ? actions.closeDimensionsModal
            : actions.openDimensionsModal
        }
        label="Exit Compare View"
        isActive={isDimensionsModalOpen}
        Icon={DimensionButton}
      />
      <Button
        onClick={
          isFactorsModalOpen
            ? actions.closeFactorsModal
            : actions.openFactorsModal
        }
        label="Exit Compare View"
        isActive={isFactorsModalOpen}
        Icon={FactorsButton}
      />
      {hasComparingState ? (
        <Button
          onClick={actions.exitCompareState}
          label="Exit Compare View"
          isActive={true}
          Icon={X}
        />
      ) : (
        <Button
          onClick={hasDefaultState ? actions.openCompareStateModal : null}
          label="Compare States"
          Icon={CompareButton}
        />
      )}
      <Button
        onClick={actions.setCountryView}
        label="Exit Compare View"
        Icon={UsMapButton}
        isActive={isCountryView}
      />
    </div>
  );
}
export default FooterButtons;
