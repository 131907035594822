import { useRecoilValue } from "recoil";
import { dimensionsData$, focused$, useRootState } from "../state.js";
import InfoPopup from "./InfoPopup.js";

import _ from "lodash";
import { useAnalytics } from "../analytics/analytics.js";

function Dimensions() {
  const data = useRecoilValue(dimensionsData$);
  const [rootState, actions] = useRootState();
  const { focusType, focusValue } = useRecoilValue(focused$);
  const {logClickEvent} = useAnalytics();
  return (
    <div>
      <div className="flex flex-row">
        <h2 className="text-high text-xl font-bold mb-2 flex">
          Well-Being Dimensions <InfoPopup info="dimensions" />
        </h2>
        <div className="flex flex-row pt-1" style={{marginLeft: "auto"}}>
          <div className="pt-1 mr-2" style={{ marginLeft: "-1px" }}>
            <div className="dimensions-country-average-marker rounded relative"></div>
          </div>
          <div className="text-base font-medium">National Average</div>
        </div>
      </div>

      <div className="mx-3">
        <div className="graph-container relative border-t border-b border-superlow">
          <div className="line-container absolute grid grid-cols-5 w-1/2 left-1/2 -top-2 -bottom-2 z-10">
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-r border-dashed border-superlow"></div>
          </div>
          {_.map(data, (d, ndx) => {
            const label = _.get(d, "label");
            const isActive =
              !focusType || (focusType === "dimension" && focusValue === label);
            const backgroundColor = isActive ? d.color : "#ccd6dd";
            return (
              <div
                className={`relative z-10 flex items-center justify-between cursor-pointer py-2 pl-4 ${ndx % 2 === 0 ? "bg-ultralow" : "" }`}
                key={label}
                onClick={(e) => {
                  actions.setFocused("dimension", label);
                  logClickEvent({ filterType: "dimension", filters: [label]})
                }}
              >
                <div className="w-1/2 text-base font-medium flex items-center ">
                  <span
                    className={`transition-all ease-out duration-500 ${
                      isActive ? "text-high text-full" : "text-medium"
                    }`}
                  >
                    {label}
                  </span>
                </div>
                <div className="relative flex-auto z-10 dimensions-item">
                  <div
                    className="dimensions-country-average-marker transition-all duration-500 ease-out rounded absolute"
                    style={{
                      left: `calc(${_.get(d, "countryValue.score")}% - 4px)`,
                    }}
                  ></div>
                  <div
                    className="dimensions-bar transition-all duration-500 ease-out"
                    style={{
                      width: `${_.get(d, "value.score")}%`,
                      background: backgroundColor,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="score-container flex pt-2 text-medium">
          <div className="w-1/2 flex justify-between pr-4">
            <div className="text-base font-medium" style={{marginLeft: "auto"}}>Score</div>
          </div>
          <div className=" w-1/2 ">
            <div className="score-labels-container flex flex-row justify-between">
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                0
              </div>
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                20
              </div>
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                40
              </div>
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                60
              </div>
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                80
              </div>
              <div className="score-label-container flex items-center justify-center text-base font-medium">
                100
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dimensions;
