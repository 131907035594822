import SelectIcon, {selectStyles, DropdownIndicator} from "./Select";
import Select from "react-select";
import { ReactComponent as ResetIcon } from "./icons/Reset.svg";
import {
  stateFilterData$,
  filterValueLabel$,
  filterType$,
  countryFilterValueLabel$,
  useRootState,
  isResetable$,
  countryViewByOptions
} from "../state.js";
import { stateLabelValues } from "../domain/states.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { useAnalytics } from "../analytics/analytics";

function FilterCountry() {
  const [root, actions] = useRootState();
  const countryFilterData = useRecoilValue(stateFilterData$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);
  const filterType = useRecoilValue(filterType$);
  const countryFilterValueLabel = useRecoilValue(countryFilterValueLabel$);
  const isResetable = useRecoilValue(isResetable$);

  const { logClickEvent } = useAnalytics();
  
  return (
      <div className="flex flex-row items-center justify-between">
          <div className="flex w-5/6">
            <div className="w-1/2 pr-1">
                <Select
                  styles={{...selectStyles, valueContainer: (provided) => ({ ...provided, margin: 0})}}
                  options={countryViewByOptions}
                  value={countryFilterValueLabel}
                  isClearable={false}
                  onChange={({value}) => {
                      actions.setFilterType(value)
                      logClickEvent({ filterType: value });
                    }}
                  components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                  }}
                />
            </div>
            <div className="w-1/2">
                <SelectIcon
                    options={[{label: "All States", value: null},  ...stateLabelValues]}
                    value={filterValueLabel || {label: "All States", value: null}}
                    isClearable={false}
                    onChange={(value) => {
                        actions.selectStateFromCountryView(_.get(value, "value"));
                        logClickEvent({ state: value.label });
                    }}
                />
            </div>
          </div>
          <div className="w-1/6 ml-4">
              <button
                  onClick={actions.reset}
                  style={{whiteSpace: "nowrap", width: "100%", borderWidth: "2px", borderRadius: "10px"}}
                  className={`p-2 text-base font-medium transition-all duration-500 ease-out text-medium focus:outline-none ${isResetable ? "border border-medium" : "cursor-not-allowed"}`}
              >
                  <ResetIcon className={`${isResetable ? "text-scale-1" : "text-low"} inline-block mr-1`}/> Reset
              </button>
          </div>
      </div>
  );
}

export default FilterCountry;