import { Suspense } from "react";
import CountryHeader from "../components/CountryHeader.js";

function InnerCountryPlacard() {
  return (
    <div className="bg-white px-6 pb-4 state-placard rounded-10">
      <CountryHeader />
    </div>
  );
}

function CountryPlacard() {
  return (
    <Suspense fallback={""}>
      <InnerCountryPlacard />
    </Suspense>
  );
}

export default CountryPlacard;
