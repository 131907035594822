import { stateRanking$, useRootState } from "../state.js";
import { useRecoilValue } from "recoil";

import { ColorContrastCalc } from "color-contrast-calc";

import _ from "lodash";
import { useAnalytics } from "../analytics/analytics.js";

function getLabelColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090e11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
    ? dark.hexCode
    : light.hexCode;
}

function BigStateContainer({ color, rank, score, name, onClick }) {
  return (
    <div
      className="flex flex-row items-center px-3 cursor-pointer hover:bg-ultralow w-full rounded-lg p-2"
      onClick={onClick}
    >
      <div
        className="state-rank-wrap flex items-center justify-center font-medium text-3xl mr-3"
        style={{
          background: color,
          color: getLabelColor(color),
        }}
      >
        #{rank}
      </div>
      <div className="mb-1">
        <div className="text-3xl font-bold text-high leading-none">{name}</div>
        <div>
          <span className="text-medium mr-2 text-lg">Index Score</span>
          <span className="font-medium text-extended-green text-lg">
            {score}/100
          </span>
        </div>
      </div>
    </div>
  );
}

function SmallStateContainer({ color, rank, score, name, onClick }) {
  return (
    <div
      className="flex flex-row items-center px-3 hover:bg-ultralow w-1/2 rounded-lg cursor-pointer p-2"
      onClick={onClick}
    >
      <div
        className="state-rank-mobile flex items-center justify-center font-medium text-xl mr-3"
        style={{
          background: color,
          color: getLabelColor(color),
        }}
      >
        #{rank}
      </div>
      <div className="mb-1">
        <div className="text-xl font-bold text-high leading-none">{name}</div>
        <div>
          <span className="text-medium mr-2 text-lg">Index Score</span>
          <span className="font-medium text-extended-green text-lg">
            {score}/100
          </span>
        </div>
      </div>
    </div>
  );
}

function StateRank() {
  const [root, actions] = useRootState();
  const stateRanking = useRecoilValue(stateRanking$);
  const { logClickEvent } = useAnalytics();

  return (
    <div className="flex flex-wrap mb-6">
      {_.map(stateRanking, (val, index) => {
        const name = _.get(val, "name");
        const score = _.get(val, "score");
        const rank = _.get(val, "rank");
        const color = _.get(val, "color");

        if (index <= 4) {
          return (
            <BigStateContainer
              key={rank}
              name={name}
              score={score}
              rank={rank}
              color={color}
              onClick={() => {
                actions.selectStateFromCountryView(_.get(val, "code"))
                logClickEvent({ state: name });
              }}
            />
          );
        } else
          return (
            <SmallStateContainer
              key={rank}
              name={name}
              score={score}
              rank={rank}
              color={color}
              onClick={() => {
                actions.selectStateFromCountryView(_.get(val, "code"))
                logClickEvent({ state: name });
              }}
            />
          );
      })}
    </div>
  );
}

export default StateRank;
