import axios from "axios";

let loaded = false;

const loadScript = ({ data: src }) =>
  new Promise((res, rej) => {
    if (loaded || !src) return;

    loaded = true;

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.onload = () => {
      if (window._satellite) window._satellite.pageBottom();
      // Uncomment below for debug mode
      // window._satellite.setDebug(true)
      res();
    };
    script.onerror = () => {
      rej();
    };
    document.getElementsByTagName("body")[0].appendChild(script);
  });

export const enableAnalytics = () => {
  return axios
    .get(`/getConfig/analytics_script_src`)
    .then(loadScript)
};

export const disableAnalytics = () => {
  if (!loaded) return;
  window.location.reload();
};
