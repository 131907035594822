import { useRecoilValue } from "recoil";
import { stateHeaderData$ } from "../state.js";
import { ColorContrastCalc } from "color-contrast-calc";
import InfoPopup from "./InfoPopup";

function getRankColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090E11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
    ? dark.hexCode
    : light.hexCode;
}

function StateHeader() {
  const stateHeaderData = useRecoilValue(stateHeaderData$);
  return (
    <div className="flex flex-col /100">
      <div className="flex flex-row mb-1 text-xl">
        <span className="mr-2 text-medium">Well-Being Index Score:</span>
        <span className="font-bold text-high">
          {stateHeaderData.score}
        </span>
          <span>/100</span>
        <div className="inline-block" style={{marginBottom: "-6px"}}><InfoPopup info="dimensions"/></div>
      </div>
      <div className="flex text-3xl text-high leading-none font-semibold" style={{marginBottom:"6px"}}>
        {stateHeaderData.name}
      </div>
      <div className="flex text-lg text-high mt-1 font-medium">
        U.S. Ranking #{stateHeaderData.rank} of 50
      </div>
    </div>
  );
}

export default StateHeader;