import {
  useRootState,
  isFactorsModalOpen$,
  isDimensionsModalOpen$,
  hasComparingState$,
  isCountryView$,
  isStateRankView$,
  isMsaModalOpen$,
  isCountyModalOpen$,
  pipMapsData$,
} from "./state.js";
import { useRecoilValue } from "recoil";
import Map from "./components/Map.js";
import ViewingFooter from "./mobile/ViewingFooter.js";

import ColorScale from "./components/ColorScale";
import CountryHeader from "./components/CountryHeader.js";
import PipMap from "./components/PipMap.js";

import StateHeader from "./mobile/StateHeader.js";
import FilterState from "./mobile/FilterState.js";
import FilterCountry from "./mobile/FilterCountry";
import FooterButtons from "./mobile/FooterButtons";

import CompareStateModal from "./desktop/CompareStateModal.js";
import DimensionsModal from "./mobile/DimensionsModal.js";
import FactorsModal from "./mobile/FactorsModal.js";
import FilterModal from "./mobile/FilterModal.js";

import ComparePanel from "./desktop/ComparePanel.js";
import { Suspense } from "react";
import StatesModal from "./mobile/StatesModal.js";
import Dimensions from "./mobile/Dimensions.js";

import _ from "lodash";

function PipMaps() {
  const pipMapsData = useRecoilValue(pipMapsData$);
  const [root, actions] = useRootState();
  const view = _.get(root, "view");
  return (
    <>
      {_.map(pipMapsData, (d) => {
        const action =
          "country" === view
            ? () => actions.selectStateFromCountryView(d.code)
            : "defaultState" === view
            ? () => actions.setView("comparingState")
            : () => actions.setView("defaultState");
        return (
          <PipMap
            mode="mobile"
            data={d}
            key={_.get(d, "name")}
            onClick={action}
          />
        );
      })}
    </>
  );
}

function MobileApp() {
  const hasComparingState = useRecoilValue(hasComparingState$);
  const isFactorsModalOpen = useRecoilValue(isFactorsModalOpen$);
  const isDimensionsModalOpen = useRecoilValue(isDimensionsModalOpen$);
  const isStateRankView = useRecoilValue(isStateRankView$);
  const isMsaModalOpen = useRecoilValue(isMsaModalOpen$);
  const isCountyModalOpen = useRecoilValue(isCountyModalOpen$);

  const isCountryView = useRecoilValue(isCountryView$);
  return (
    <Suspense fallback={null}>
      <div className="mobile relative w-screen h-screen grid grid-cols-12 grid-mobile-rows p-4">
        <div className="absolute inset-0">
          <Map mode="mobile" />
        </div>
        {isDimensionsModalOpen ||
        isFactorsModalOpen ||
        isCountyModalOpen ||
        isMsaModalOpen ? null : (
          <Suspense fallback={null}>
            <div className="z-20 col-span-12">
              <div className="bg-white rounded-lg p-2 my-1">
                <Suspense fallback={null}>
                  {isCountryView ? <CountryHeader /> : <StateHeader />}
                </Suspense>
              </div>
              {hasComparingState ? (
                <div className="my-2">
                  <Suspense fallback={null}>
                    <ComparePanel />
                  </Suspense>
                </div>
              ) : null}
            </div>
          </Suspense>
        )}
        {isDimensionsModalOpen ? (
          <div className="z-20 col-span-12 row-span-5 h-full">
            <Suspense fallback={null}>
              <DimensionsModal />
            </Suspense>
          </div>
        ) : null}

        {isFactorsModalOpen ? (
          <div className="z-20 col-span-12 row-span-5 h-full">
            <Suspense fallback={null}>
              <FactorsModal />
            </Suspense>
          </div>
        ) : null}

        {isCountyModalOpen ? (
          <div className="z-20 col-span-12 row-span-5 h-full">
            <Suspense fallback={null}>
              <FilterModal />
            </Suspense>
          </div>
        ) : null}

        {isMsaModalOpen ? (
          <div className="z-20 col-span-12 row-span-5 h-full">
            <Suspense fallback={null}>
              <FilterModal />
            </Suspense>
          </div>
        ) : null}

        {isDimensionsModalOpen ||
        isFactorsModalOpen ||
        isCountryView ||
        isCountyModalOpen ||
        isMsaModalOpen ? null : (
            <div className="z-20 flex items-end row-start-5 col-span-12 w-full pointer-events-none">
              <div className="w-11/12 pointer-events-auto">
                <Suspense fallback={null}>
                  <ViewingFooter />
                </Suspense>
              </div>
              <div className="w-1/12 flex ml-2">{/* Zoom buttons area  */}</div>
            </div>
        )}

        {isCountryView &&
        isStateRankView &&
        !(isDimensionsModalOpen || isFactorsModalOpen) ? (
          <div className="z-20 col-span-12 row-span-4 h-full">
            <Suspense fallback={null}>
              <StatesModal />
            </Suspense>
          </div>
        ) : null}


        {isCountryView ? (
          <div className="mobile-pip-maps-wrap absolute flex">
            <Suspense fallback={null}>
              <PipMaps />
            </Suspense>
          </div>
        ) : null}
        <div className="z-20 w-full row-start-6 col-span-12">
          {isCountryView ? (
            <div className="bg-white px-4 rounded-lg mt-2 shadow-lg">
              <Suspense fallback={null}>
                <FilterCountry />
              </Suspense>
            </div>
          ) : (
            <div className="bg-white px-4 rounded-lg mt-2 shadow-lg">
              <Suspense fallback={null}>
                <FilterState />
              </Suspense>
            </div>
          )}
          <div className="mt-3">
            <Suspense fallback={null}>
              <FooterButtons />
            </Suspense>
          </div>
        </div>
        <CompareStateModal />
      </div>
    </Suspense>
  );
}

export default MobileApp;
