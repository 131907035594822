function CountryHeader() {
  return (
    <div className="flex flex-col justify-around py-8 flex-1">
      <div>
        <div className="lg:py-0 text-xl lg:text-3xl font-medium text-high leading-none">
          United States
        </div>
      </div>
    </div>
  );
}

export default CountryHeader;
