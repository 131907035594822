import { useRecoilValue } from "recoil";
import { useRootState, comparePanelData$ } from "../state.js";
import { Switch, ChevronDown } from "../components/Icons.js";
import _ from "lodash";

function ComparePanel() {
  const [root, actions] = useRootState();
  const comparePanelData = useRecoilValue(comparePanelData$);

  return (
    <div className="bg-ultralow p-2 rounded-10 compare-panel">
      <button
        className={`text-lg font-medium text-high rounded p-3 transition-all duration-500 ease-out leading-1 h-12 ${
          _.get(comparePanelData, "view") === "defaultState"
            ? "bg-primary-green"
            : "bg-superlow"
        }`}
        onClick={(e) => actions.setView("defaultState")}
      >
        {_.get(comparePanelData, "defaultStateName")}
      </button>
      <div className="text-base py-3 px-1">
        <Switch />
      </div>
      <div className="flex">
        <button
          className={`flex-grow text-lg font-medium text-high rounded-l p-3 transition-all duration-500 ease-out leading-1 h-12 ${
            _.get(comparePanelData, "view") === "comparingState"
              ? "bg-primary-green"
              : "bg-superlow"
          }`}
          onClick={(e) => actions.setView("comparingState")}
        >
          {_.get(comparePanelData, "comparingStateName")}
        </button>
        <button
          className="text-lg font-medium rounded-r p-3 transition-all duration-500 ease-out bg-white w-10 h-12 block text-low hover:text-primary-green"
          onClick={actions.openCompareStateModal}
        >
          <div className="-mx-2 -mt-1">
            <ChevronDown />
          </div>
        </button>
      </div>
    </div>
  );
}

export default ComparePanel;
