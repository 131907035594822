import MapGl, {
  StaticMap,
  WebMercatorViewport,
  Source,
  Layer,
} from "react-map-gl";

import { useRecoilValue } from "recoil";
import { useState, useEffect, useRef, useMemo, Suspense } from "react";

const MAPBOX_TOKEN =
  "pk.eyJ1Ijoic2hhcmVjYXJlIiwiYSI6ImNrOW1tNzF1YTA5Ym4zbW9xaWU0MnppdXcifQ.avIIGIUxlm7fSbmzPujXHg";

function InnerPipMap({ mode, data, onClick }) {
  const { bbox, color, featureIds } = data;

  const viewport = useMemo(() => {
    const width = mode === "desktop" ? 200 : 100;
    const height = mode === "desktop" ? 190 : 90;
    const [minX, minY, maxX, maxY] = bbox;
    return new WebMercatorViewport({ width, height }).fitBounds(
      [
        [minX, minY],
        [maxX, maxY],
      ],
      { padding: 5 }
    );
  }, [bbox, mode]);

  return (
    <div
      className={`bg-white overflow-hidden shadow-lg mr-4 relative ${
        mode === "desktop" ? "desktop-pip-map-wrap" : "mobile-pip-map-wrap"
      }`}
    >
      <div className="inner-pip-map-wrap">
        <StaticMap
          width="100%"
          height="100%"
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle="mapbox://styles/sharecare/ckiujqxkk2rib19qjw44czxpa"
          longitude={-124.733174}
          latitude={51.61274}
          {...viewport}
        >
          <Source
            id="states"
            type="vector"
            url="mapbox://mapbox.boundaries-adm1-v3"
          >
            <Layer
              source-layer="boundaries_admin_1"
              id="highlighted-counties-fill"
              type="fill"
              filter={["in", ["id"], ["literal", []]]}
              paint={{
                "fill-outline-color": "#FFF",
              }}
            ></Layer>
            <Layer
              source-layer="boundaries_admin_1"
              id="highlighted-states-fill"
              type="fill"
              filter={["in", ["id"], ["literal", featureIds]]}
              paint={{
                "fill-color": color,
              }}
            ></Layer>
            <Layer
              source-layer="boundaries_admin_1"
              id="highlighted-states-outline"
              type="line"
              filter={["in", ["id"], ["literal", featureIds]]}
              paint={{
                "line-color": "#FFF",
                "line-width": 1,
              }}
            ></Layer>
          </Source>
        </StaticMap>
        <div className="text-base font-bold text-center text-high leading-7">
          {data.name}
        </div>
      </div>
      <div className="absolute inset-0 cursor-pointer" onClick={onClick}></div>
    </div>
  );
}

function PipMap(props) {
  return (
    <Suspense fallback={null}>
      <InnerPipMap {...props} />
    </Suspense>
  );
}

export default PipMap;
