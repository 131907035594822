import { useRecoilValue } from "recoil";
import {
  focused$,
  filterType$,
  isStateView$,
  filterValueLabel$,
} from "../state.js";
import ColorScale from "../components/ColorScale";

function ViewingFooter() {
  const { focusType, focusValue } = useRecoilValue(focused$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);
  let label = "Well-Being Index Scores";
  let labelClass = "text-info";

  if ("factor" === focusType) {
    label = focusValue;
    labelClass = "text-lime-100";
  } else if ("dimension" === focusType) {
    label = focusValue;
    labelClass = "text-purple-90";
  } else if (filterValueLabel) {
    label = filterValueLabel.label;
  }

  return (
    <div className="bg-white rounded-10 text-center text-base font-medium text-high text-lg" style={{padding: "0.25rem 0.25rem 0"}}>
      <div className="flex flex-col">
        <ColorScale />
        <div className="flex flex-row items-center justify-center">
          Viewing data for: <span className={`${labelClass} pl-1`}>{label}</span>
        </div>
      </div>
    </div>
  );
}

export default ViewingFooter;
