import useBreakpoint from "use-breakpoint";
import MobileApp from "./MobileApp.js";
import DesktopApp from "./DesktopApp.js";

const BREAKPOINTS = { mobile: 0, desktop: 640 };

function App() {
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "desktop");
  const AppTag = "mobile" === breakpoint ? MobileApp : DesktopApp;

  return (
    <>
      <AppTag />
    </>
  );
}

export default App;
