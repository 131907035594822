import {
  useRootState,
  isCompareStateModalOpen$,
  comparableStates$,
  defaultStateName$,
} from "../state.js";
import { useRecoilValue } from "recoil";
import AriaModal from "react-aria-modal";
import Select from "../components/Select.js";
import { Switch, X } from "../components/Icons.js";

function getApplicationNode() {
  return document.getElementById("root");
}

function CompareStateModal() {
  const [root, actions] = useRootState();
  const isCompareStateModalOpen = useRecoilValue(isCompareStateModalOpen$);
  const comparableStates = useRecoilValue(comparableStates$);
  const defaultStateName = useRecoilValue(defaultStateName$);

  return (
    <AriaModal
      onExit={actions.closeCompareStateModal}
      mounted={isCompareStateModalOpen}
      getApplicationNode={getApplicationNode}
      titleText="Choose a state to compare"
      verticallyCenter={true}
    >
      <div className="compare-state-modal bg-white rounded-10 mx-auto p-6 shadow-xl">
        <div className="flex justify-end -mt-3 -mr-3">
          <button
            className="text-primary-green w-8 h-8"
            onClick={actions.closeCompareStateModal}
          >
            <X />
          </button>
        </div>
        <div className="text-center text-2xl font-medium leading-7">
          Choose a state to
          <br /> compare with {defaultStateName}
        </div>
        <div className="flex items-center justify-center py-8">
          <div className="w-6 h-6 text-low">
            <Switch />
          </div>
        </div>
        <div className="px-12 md:px-24 mb-6">
          <Select
            options={comparableStates}
            placeholder="Pick a State"
            onChange={({ value }) => {
              if (value) {
                actions.compareState(value);
              }
            }}
          />
        </div>
      </div>
    </AriaModal>
  );
}

export default CompareStateModal;
