import Factors from "../components/Factors.js";

function FactorsModal() {
  return (
    <div className="h-full w-full rounded-md bg-white shadow-lg p-6">
      <Factors mode="mobile" />
    </div>
  );
}

export default FactorsModal;
