import { useRecoilValue } from "recoil";
import { dimensionsData$, focused$, useRootState } from "../state.js";
import InfoPopup from "../components/InfoPopup.js";

import _ from "lodash";
import { useAnalytics } from "../analytics/analytics.js";

function Dimensions() {
  const data = useRecoilValue(dimensionsData$);
  const [rootState, actions] = useRootState();
  const { focusType, focusValue } = useRecoilValue(focused$);
  const {logClickEvent} = useAnalytics();
  return (
    <div className="w-full relative h-full flex justify-between items-start flex-col">
      <h2 className="text-high text-xl font-bold mb-2 flex">
        Well-Being Dimensions <InfoPopup info="dimensions" />
      </h2>
      <div className="w-full flex-auto flex flex-col">
        <div className="w-full">
          <div className="text-base text-medium">Score</div>
          <div className="score-labels-container-mobile text-medium pb-2 -mr-3">
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              0
            </div>
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              20
            </div>
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              40
            </div>
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              60
            </div>
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              80
            </div>
            <div className="score-label-container-mobile flex items-center justify-start text-base font-medium text-medium">
              100
            </div>
          </div>
        </div>
        <div className="flex-auto graph-container flex flex-col justify-around relative border-t border-b border-superlow py-1">
          <div className="line-container absolute grid grid-cols-5 w-full -top-2 -bottom-2 z-10">
            <div className="line h-full border-l border-dashed border-white"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-dashed border-superlow"></div>
            <div className="line h-full border-l border-r border-dashed border-superlow"></div>
          </div>
          {_.map(data, (d) => {
            const label = _.get(d, "label");
            const isActive =
              !focusType || (focusType === "dimension" && focusValue === label);
            const backgroundColor = isActive ? d.color : "#ccd6dd";
            return (
              <div
                className="relative z-10 cursor-pointer py-1"
                key={label}
                onClick={(e) => {
                  actions.setFocused("dimension", label);
                  logClickEvent({ filterType: "dimension", filters: [label]})
                }}
              >
                <div className="w-full text-base font-medium flex items-center ">
                  <div
                    className={`rounded-lg w-2 h-2 mr-2 transition-all ease-out duration-500 ${
                      isActive ? "bg-primary-green" : "bg-superlow"
                    }`}
                  ></div>
                  <span
                    className={`transition-all ease-out duration-500 ${
                      isActive ? "text-high" : "text-medium"
                    }`}
                  >
                    {label}
                  </span>
                </div>
                <div className="relative flex-auto z-10 dimensions-item">
                  <div
                    className="dimensions-country-average-marker transition-all duration-500 ease-out rounded absolute"
                    style={{
                      left: `calc(${_.get(d, "countryValue.score")}% - 4px)`,
                    }}
                  ></div>
                  <div
                    className="dimensions-bar transition-all duration-500 ease-out"
                    style={{
                      width: `${_.get(d, "value.score")}%`,
                      background: backgroundColor,
                    }}
                  ></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row relative mt-2">
        <div className="pt-1 pl-1 mr-2" style={{ marginLeft: "-1px" }}>
          <div className="dimensions-country-average-marker rounded relative"></div>
        </div>
        <div className="text-base font-medium">National Average</div>
      </div>
    </div>
  );
}

export default Dimensions;
