import Select, { components } from "react-select";
import { ReactComponent as CaretDown } from "./icons/CaretDown.svg";

export const selectStyles = {
  control: (provided, { isFocused }) => {
    return {
      ...provided,
      borderRadius: "10px",
      borderWidth: "2px",
      borderColor: "#5d6a74",
      fontSize: "0.875rem",
      padding: "5px"
    };
  },
  dropdownIndicator: () => ({padding: 0}),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: "32px",
  }),
  option: (provided, { isFocused, isSelected }) => {
    return {
      ...provided,
      fontSize: "0.875rem",
      backgroundColor: isSelected ? "#00BFA5" : isFocused ? "#8ee8dc" : "transparent",
    };
  },
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
  }),
};

export const DropdownIndicator = ({ children, ...props }) => {
  return (
      <components.DropdownIndicator {...props}>
        <CaretDown className="h-8 w-8"/>
        {children}
      </components.DropdownIndicator>
  );
};

const ValueContainer = ({ children, ...props }) => (
  <components.ValueContainer {...props}>
    <div className="absolute left-0 ml-2">
      <svg
        className="w-5 h-5"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M27.8125 26.5938L21.7656 20.5469C21.6719 20.4531 21.5312 20.4062 21.3906 20.4062H20.875C22.5156 18.625 23.5 16.3281 23.5 13.75C23.5 8.40625 19.0938 4 13.75 4C8.35938 4 4 8.40625 4 13.75C4 19.1406 8.35938 23.5 13.75 23.5C16.2812 23.5 18.625 22.5156 20.3594 20.9219V21.3906C20.3594 21.5781 20.4062 21.7188 20.5 21.8125L26.5469 27.8594C26.7812 28.0938 27.1094 28.0938 27.3438 27.8594L27.8125 27.3906C28.0469 27.1562 28.0469 26.8281 27.8125 26.5938ZM13.75 22C9.15625 22 5.5 18.3438 5.5 13.75C5.5 9.20312 9.15625 5.5 13.75 5.5C18.2969 5.5 22 9.20312 22 13.75C22 18.3438 18.2969 22 13.75 22Z"
          fill="#5D6A74"
        />
      </svg>
    </div>
    {children}
  </components.ValueContainer>
);

function WrappedSelect({styles, ...props}) {
  return (
    <Select
      styles={{...selectStyles, ...styles}}
      classNamePrefix="filter-select"
      {...props}
      components={{
        DropdownIndicator,
        ValueContainer,
        IndicatorSeparator: () => null
      }}
    />
  );
}

export default WrappedSelect;
