import { useRecoilValue } from "recoil";
import { useRootState, hasComparingState$ } from "../state.js";
import { Switch, ChevronLeft, UsMap } from "../components/Icons.js";

function Button({ Icon, label, onClick }) {
  return (
    <button
      onClick={onClick}
      className="bg-white rounded-10 font-bold pl-4 pr-6 py-4 text-medium hover:text-high flex flex-row shadow-lg mr-2 "
    >
      <div className="w-6 h-6 text-primary-green mr-2 mr-4">
        <Icon />
      </div>
      <span className="text-high text-xl">{label}</span>
    </button>
  );
}

function HeaderButtons() {
  const [root, actions] = useRootState();
  const hasComparingState = useRecoilValue(hasComparingState$);
  return (
    <div className="flex">
      {hasComparingState ? (
        <Button
          onClick={actions.exitCompareState}
          label="Exit Compare View"
          Icon={ChevronLeft}
        />
      ) : (
        <Button
          onClick={actions.openCompareStateModal}
          label="Compare States"
          Icon={Switch}
        />
      )}
      <Button
        onClick={actions.setCountryView}
        label="View U.S."
        Icon={UsMap}
      />
    </div>
  );
}
export default HeaderButtons;
