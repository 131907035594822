import { useRecoilValue } from "recoil";
import { stateHeaderData$ } from "../state.js";
import { ColorContrastCalc } from "color-contrast-calc";
import { useRootState, isResetable$ } from "../state.js";
import {ReactComponent as ResetIcon} from "../components/icons/Reset.svg";

function getRankColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090E11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
    ? dark.hexCode
    : light.hexCode;
}

function StateHeader() {
  const [rootState, actions] = useRootState();
  const isResetable = useRecoilValue(isResetable$);
  const stateHeaderData = useRecoilValue(stateHeaderData$);
  return (
    <div className="flex flex-row items-center px-3">
      <div
        className="state-rank-mobile flex items-center justify-center font-medium text-xl mr-3"
        style={{
          background: stateHeaderData.color,
          color: getRankColor(stateHeaderData.color),
        }}
      >
        #{stateHeaderData.rank}
      </div>
      <div className="mb-1">
        <div>
          <span className="text-medium mr-2 text-lg">Index Score</span>
          <span className="font-medium text-high text-lg">
            {stateHeaderData.score}/100
          </span>
        </div>
        <div className="text-xl font-bold text-high leading-none">
          {stateHeaderData.name}
        </div>
      </div>
      <div className="flex ml-auto">
            <button
                onClick={actions.reset}
                style={{whiteSpace: "nowrap", width: "100%", borderWidth: "2px", borderRadius: "10px"}}
                className={`p-2 text-base font-medium transition-all duration-500 ease-out text-medium focus:outline-none ${isResetable ? "border border-medium" : "cursor-not-allowed"}`}
            >
                <ResetIcon className={`${isResetable ? "text-scale-1" : "text-low"} inline-block mr-1`}/> Reset
            </button>
      </div>
    </div>
  );
}

export default StateHeader;
