import { stateRanking$, useRootState } from "../state.js";
import { useRecoilValue } from "recoil";
import { useAnalytics } from "../analytics/analytics"
import { ColorContrastCalc } from "color-contrast-calc";

import _ from "lodash";

function getLabelColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090e11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
    ? dark.hexCode
    : light.hexCode;
}

function StateRank() {
  const [root, actions] = useRootState();
  const stateRanking = useRecoilValue(stateRanking$);
  const { logClickEvent } = useAnalytics();

  return (
    <div className="flex flex-col stateRanking ">
      {_.map(stateRanking, (val, index) => {
        const name = _.get(val, "name");
        const score = _.get(val, "score");
        const rank = _.get(val, "rank");
        const color = _.get(val, "color");
        const code = _.get(val, "code");

        return (
          <div
            key={code}
            className="flex flex-row items-center px-3 my-2 w-full"
            onClick={() => {
              actions.selectStateFromCountryView(_.get(val, "code"))
              logClickEvent({ state: name });
            }}
          >
            <div
              className="state-rank-mobile flex items-center justify-center font-medium text-xl mr-3"
              style={{
                background: color,
                color: getLabelColor(color),
              }}
            >
              #{rank}
            </div>
            <div className="mb-1">
              <div className="text-xl font-bold text-high leading-none">
                {name}
              </div>
              <div>
                <span className="text-medium mr-2 text-lg">Index Score</span>
                <span className="font-medium text-extended-green text-lg">
                  {score}/100
                </span>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default StateRank;
