import Select from "../components/Select";
import {
  stateFilterData$,
  filterValueLabel$,
  filterType$,
  useRootState,
  isMsaModalOpen$,
  isCountyModalOpen$,
} from "../state.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { useAnalytics } from "../analytics/analytics";

import { ChevronDown } from "../components/Icons.js";

function FilterState() {
  const [root, actions] = useRootState();
  const stateFilterData = useRecoilValue(stateFilterData$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);
  const filterType = useRecoilValue(filterType$);
  const isMsaModalOpen = useRecoilValue(isMsaModalOpen$);
  const isCountyModalOpen = useRecoilValue(isCountyModalOpen$);

  const { logClickEvent } = useAnalytics();

  return (
    <div className="flex flex-row items-center justify-center py-4">
      <div className="flex flex-row w-full justify-between">
        <div className="w-1/2">
          <input
            type="radio"
            className="hidden"
            id="filter-county"
            onChange={(e) => {
              e.target.checked && actions.setFilterType("county");
              logClickEvent({ filterType: 'county' });
            }}
            checked={filterType === "county"}
          />
          <label
            htmlFor="filter-county"
            className="font-medium text-high text-lg flex items-center"
          >
            <span className="w-4 h-4 inline-block mr-2 rounded-full border-primary-green flex-no-shrink border-2"></span>
            County
          </label>
        </div>
        <button
          className="w-full mx-2"
          onClick={
            isCountyModalOpen
              ? actions.closeCountyModal
              : actions.openCountyModal
          }
        >
          <div
            className={`w-6 h-6 -my-2  self-center mx-auto transition transition-transform transition-ease-in-out transform ${
              isCountyModalOpen ? "" : "rotate-180"
            }`}
          >
            <ChevronDown />
          </div>
        </button>
        <div className="w-1/2">
          <input
            type="radio"
            className="hidden"
            id="filter-msa"
            onChange={(e) => {
              e.target.checked && actions.setFilterType("msa");
              logClickEvent({ filterType: 'msa' });
            }}
            checked={filterType === "msa"}
          />
          <label
            htmlFor="filter-msa"
            className="font-medium text-high text-lg flex items-center"
          >
            <span className="w-4 h-4 inline-block mr-2 rounded-full border-primary-green flex-no-shrink border-2"></span>
            City/Metro
          </label>
        </div>
        <button
          className="w-full mx-2 "
          onClick={
            isMsaModalOpen ? actions.closeMsaModal : actions.openMsaModal
          }
        >
          <div
            className={`w-6 h-6  -my-2 transition transition-transform transition-ease-in-out self-center mx-auto transform ${
              isMsaModalOpen ? "" : "rotate-180"
            }`}
          >
            <ChevronDown />
          </div>
        </button>
      </div>
    </div>
  );
}

export default FilterState;
