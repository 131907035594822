import { useRecoilValue } from "recoil";
import { useState, useEffect, useRef, useLayoutEffect } from "react";
import { factorsData$, focused$, useRootState } from "../state.js";
import _ from "lodash";
import useDimensions from "../UseDimensions.ts";
import { treemapSquarify } from "d3-hierarchy";
import { ColorContrastCalc } from "color-contrast-calc";
import InfoPopup from "./InfoPopup.js";
import { useAnalytics } from "../analytics/analytics.js";

function getTreemapD3(data, width, height) {
  const tData = _.sortBy(
    _.map(data, (d) => {
      return { ...d };
    }),
    (d) => d.value * -1
  );
  const rootValue = _.reduce(
    data,
    (acc, d) => {
      return acc + d.value;
    },
    0
  );
  treemapSquarify.ratio(2.5)(
    {
      value: rootValue,
      children: tData,
    },
    0,
    0,
    width,
    height
  );

  return _.sortBy(tData, (d) => d.label);
}

function getLabelColor(backgroundColor) {
  const dark = ColorContrastCalc.colorFrom("#090e11");
  const light = ColorContrastCalc.colorFrom("#ffffff");
  const bg = ColorContrastCalc.colorFrom(backgroundColor);

  return bg.contrastRatioAgainst(dark) > bg.contrastRatioAgainst(light)
    ? dark.hexCode
    : light.hexCode;
}

const LABEL_STATES = {
  initial: { next: "centerVertically" },
  centerVertically: { next: "initialVertical" },
  initialVertical: { next: "centerHorizontally" },
  centerHorizontally: {},
};

function Label({ label, width, height, isActive, backgroundColor }) {
  const [labelState, setlabelState] = useState("initial");
  const [showLabel, setShowLabel] = useState(false);
  const labelRef = useRef(null);

  useLayoutEffect(() => {
    if (labelRef.current) {
      const domRect = {
        width: labelRef.current.scrollWidth,
        height: labelRef.current.scrollHeight,
      };

      if (
        ((labelState === "initial" || labelState === "centerVertically") &&
          Math.ceil(height) >= domRect.height &&
          Math.ceil(width) >= domRect.width) ||
        ((labelState === "initialVertical" ||
          labelState === "centerHorizontally") &&
          Math.ceil(height) >= domRect.width &&
          Math.ceil(width) >= domRect.height)
      ) {
        setShowLabel(true);
      } else {
        const nextLabelState = _.get(LABEL_STATES, [labelState, "next"]);
        if (nextLabelState) {
          setlabelState(nextLabelState);
        }
      }
    }
  }, [label, width, height, labelState]);

  let stylesAndClasses = {
    wrapper: { style: {}, classes: "" },
    label: { style: {}, classes: "" },
  };

  switch (labelState) {
    case "initial":
      stylesAndClasses.wrapper.style = {
        width: `${width}px`,
        height: `${height}px`,
      };
      stylesAndClasses.label.classes = "p-2";
      break;
    case "centerVertically":
      stylesAndClasses.wrapper.style = {
        width: `${width}px`,
        height: `${height}px`,
      };
      stylesAndClasses.wrapper.classes = "flex items-center";
      stylesAndClasses.label.classes = "px-2";
      stylesAndClasses.label.style = { marginTop: "-1px" };
      break;
    case "initialVertical":
      stylesAndClasses.wrapper.style = {
        width: `${height}px`,
        height: `${width}px`,
        transformOrigin: "top left",
        transform: `rotate(90deg) translateY(-${width}px)`,
      };
      stylesAndClasses.label.classes = "p-2";
      break;
    case "centerHorizontally":
      stylesAndClasses.wrapper.style = {
        width: `${height}px`,
        height: `${width}px`,
        transformOrigin: "top left",
        transform: `rotate(90deg) translateY(-${width}px)`,
      };
      stylesAndClasses.wrapper.classes = "flex items-center";
      stylesAndClasses.label.classes = "px-2";
      break;
    default:
  }

  stylesAndClasses.label.style.color = isActive
    ? getLabelColor(backgroundColor)
    : null;

  return (
    <div
      style={stylesAndClasses.wrapper.style}
      className={`leading-none ${stylesAndClasses.wrapper.classes} ${
        !isActive ? "text-medium" : ""
      } ${showLabel ? "opacity-100" : "opacity-0"}`}
    >
      <div
        className={`${stylesAndClasses.label.classes} overflow-hidden relative`}
        style={stylesAndClasses.label.style}
        ref={labelRef}
      >
        {label}
      </div>
    </div>
  );
}

function Factors({ mode }) {
  const [rootState, actions] = useRootState();
  const data = useRecoilValue(factorsData$);
  const [factorsData, setFactorsData] = useState([]);
  const [ref, { width, height }] = useDimensions();
  const { focusType, focusValue } = useRecoilValue(focused$);
  const { logClickEvent } = useAnalytics();

  useEffect(() => {
    if (width && height && data && data.length) {
      setFactorsData(getTreemapD3(data, width, height));
    }
  }, [width, height, data]);

  return (
    <div
      className={`flex flex-col ${
        mode === "mobile" ? "h-full w-full" : "mt-4"
      }`}
    >
      <h2 className="text-high text-xl font-bold mb-2 flex">
        Key Social Factors <InfoPopup info="factors" />
      </h2>
      <div
        className={`relative ${
          mode === "mobile" ? "flex-grow" : "treemap-wrap"
        }`}
        ref={ref}
      >
        {_.map(factorsData, (val, index) => {
          const label = _.get(val, "label");
          const isActive =
            !focusType || (focusType === "factor" && focusValue === label);
          const backgroundColor = isActive ? val.color : "#ccd6dd";
          const width = val.x1 - val.x0;
          const height = val.y1 - val.y0;
          return (
            <div
              key={label}
              className="treemap-value absolute overflow-hidden transition-all duration-500 ease-out cursor-pointer"
              onClick={(e) => {
                actions.setFocused("factor", label);
                logClickEvent({ filterType: 'factor', filters: [label] });
              }}
              style={{
                top: `${val.y0}px`,
                left: `${val.x0}px`,
                width: `${width}px`,
                height: `${height}px`,
              }}
            >
              <div
                className="treemap-rounded w-full h-full transition-all duration-500 ease-out text-sm font-medium"
                style={{ background: backgroundColor }}
              >
                <Label
                  key={`${label}-${width}-${height}`}
                  label={label}
                  width={width}
                  height={height}
                  backgroundColor={backgroundColor}
                  isActive={isActive}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Factors;
