import axios from "axios";
import _ from "lodash";
import produce from "immer";
import { atom, selector, useRecoilState } from "recoil";
import { STATES_BBOXES, MAINLAND_BBOX } from "./domain/bboxes.js";
import adminData from "./domain/adminData.json";
import countyFipsToGeoPoint from "./domain/countyFipsToGeoPoint.json";
import stateToGeoPoint from "./domain/stateToGeoPoint.json";
import { stateLabelValues, stateCodeToName } from "./domain/states.js";

function parseQuery(queryString) {
  var query = {};
  var pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}

const {location: {search}} = window
const parsedQuery = parseQuery(search);

const DEFAULT_STATE = _.get(parsedQuery, "defaultState")
  ? (_.get(parsedQuery, "defaultState") + "").toUpperCase()
  : null;

const DEFAULT_VIEW = DEFAULT_STATE
  ? _.get(parsedQuery, "defaultView", "defaultState")
  : "country";

const STATE_API_URL = `/api/cwbi-content/state/`;
const COUNTRY_API_URL = `/api/cwbi-content/all`;

const COLORS = {
  blue: {
    1: "#a8dbff",
    2: "#66bfff",
    3: "#0a99ff",
    4: "#006bb8",
    5: "#00477a",
  },
  purple: {
    1: "#c39eec",
    2: "#a16adc",
    3: "#824abf",
    4: "#663a97",
    5: "#4b2a6f",
  },
  lime: {
    1: "#f9fbe7",
    2: "#e6ee9c",
    3: "#d4e157",
    4: "#c0ca34",
    5: "#9e9d24",
  },
};

export const FIPS_TO_STATE = {
  "01": "AL",
  "02": "AK",
  "04": "AZ",
  "05": "AR",
  "06": "CA",
  "08": "CO",
  "09": "CT",
  10: "DE",
  12: "FL",
  13: "GA",
  15: "HI",
  16: "ID",
  17: "IL",
  18: "IN",
  19: "IA",
  20: "KS",
  21: "KY",
  22: "LA",
  23: "ME",
  24: "MD",
  25: "MA",
  26: "MI",
  27: "MN",
  28: "MS",
  29: "MO",
  30: "MT",
  31: "NE",
  32: "NV",
  33: "NH",
  34: "NJ",
  35: "NM",
  36: "NY",
  37: "NC",
  38: "ND",
  39: "OH",
  40: "OK",
  41: "OR",
  42: "PA",
  44: "RI",
  45: "SC",
  46: "SD",
  47: "TN",
  48: "TX",
  49: "UT",
  50: "VT",
  51: "VA",
  53: "WA",
  54: "WV",
  55: "WI",
  56: "WY",
  60: "AS",
  66: "GU",
  69: "MP",
  72: "PR",
  78: "VI",
};

function getColorScale(focusType) {
  return "dimension" === focusType ? "purple" : "factor" === focusType ? "lime" : "blue";
}

function normalizeFips(fips) {
  const fipsVal = fips + "";
  if (fipsVal.length <= 2) {
    return _.padStart(fipsVal, 2, "0");
  }
  return _.padStart(fipsVal, 5, "0");
}

function valueToColor(val) {
  const intVal = parseInt(val, 10);
  if (intVal < 20) {
    return 5;
  } else if (intVal < 40) {
    return 4;
  } else if (intVal < 60) {
    return 3;
  } else if (intVal < 80) {
    return 2;
  }
  return 1;
}

function makeRootStateActions(root, setRoot) {
  return _.reduce(
    ACTIONS,
    (acc, action, actionName) => {
      acc[actionName] = function () {
        var args = [].slice.call(arguments);
        var newVal = produce(root, (draft) => {
          return action.apply(ACTIONS, [draft].concat(args));
        });

        setRoot(newVal);
      };
      return acc;
    },
    {}
  );
}

export const useRootState = () => {
  const [root, setRoot] = useRecoilState(root$);
  return [root, makeRootStateActions(root, setRoot)];
};

const CACHE = {};

export function getStateData(state) {
  const cached = _.get(CACHE, ["states", state]);
  if (cached) {
    return cached;
  } else {
    const req = axios.get(`${STATE_API_URL}${state}`).then(({ data }) => data);
    _.set(CACHE, ["states", state], req);
    return req;
  }
}

export function getCountryData() {
  const cached = _.get(CACHE, "country");
  if (cached) {
    return cached;
  } else {
    const req = axios.get(COUNTRY_API_URL).then(({ data }) => data);
    _.set(CACHE, "country", req);
    return req;
  }
}

function getStateAttr(root) {
  const view = _.get(root, "view");
  if ("defaultState" === view) {
    return "defaultState";
  } else if ("comparingState" === view) {
    return "comparingState";
  }
  return "country";
}

function makeDefaultStateState(state) {
  return {
    code: state,
    focused: null,
    filterType: "county",
    filter: null,
  };
}

export const root$ = atom({
  key: "root",
  default: {
    view: DEFAULT_VIEW,
    defaultState: DEFAULT_STATE ? makeDefaultStateState(DEFAULT_STATE) : null,
    comparingState: null,
    isSidebarOpen: true,
    isCompareStateModalOpen: false,
    isDimensionsModalOpen: false,
    isFactorsModalOpen: false,
    isCountyModalopen: false,
    isMsaModalopen: false,
    country: {
      focused: null,
      filterType: "rankings",
    },
  },
});

export const data$ = selector({
  key: "data",
  get: ({ get }) => {
    const root = get(root$);
    const view = _.get(root, "view");
    if ("defaultState" === view) {
      let stateCode = _.get(root, "defaultState.code");
      return getStateData(stateCode);
    } else if ("comparingState" === view) {
      let stateCode = _.get(root, "comparingState.code");
      return getStateData(stateCode);
    }
    return getCountryData();
  },
});

const countryData$ = selector({
  key: "countryData",
  get: ({ get }) => {
    return getCountryData();
  },
});

const countiesByFIPS$ = selector({
  key: "countiesByFIPS",
  get: ({ get }) => {
    const data = get(data$);
    const counties = _.get(data, "state.county");

    return _.reduce(
      counties,
      (acc, c) => {
        acc[normalizeFips(_.get(c, "FIPS"))] = c;
        return acc;
      },
      {}
    );
  },
});

export const boundingBox$ = selector({
  key: "boundingBox",
  get: ({ get }) => {
    const root = get(root$);
    const view = _.get(root, "view");
    if ("defaultState" === view) {
      let stateCode = _.get(root, "defaultState.code");
      return STATES_BBOXES[stateCode];
    } else if ("comparingState" === view) {
      let stateCode = _.get(root, "comparingState.code");
      return STATES_BBOXES[stateCode];
    }
    return MAINLAND_BBOX;
  },
});

export const isSidebarOpen$ = selector({
  key: "isSidebarOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isSidebarOpen");
  },
});

export const dimensionsData$ = selector({
  key: "dimensionsData",
  get: ({ get }) => {
    const data = get(data$);
    const isStateView = get(isStateView$);
    const filterType = get(filterType$);
    const filterValue = get(filterValue$);
    if (isStateView) {
      const countryDimensionsData = _.get(data, "country.Dimensions");
      let dimensionsData = _.get(data, "state.state.0.Dimensions");
      if ("county" === filterType && filterValue) {
        dimensionsData = _.get(
          _.filter(
            _.get(data, "state.county"),
            (c) => filterValue === normalizeFips(_.get(c, "FIPS"))
          ),
          [0, "Dimensions"]
        );
      } else if ("msa" === filterType && filterValue) {
        dimensionsData = _.get(
          _.filter(
            _.get(data, "state.msa"),
            (c) => filterValue === _.get(c, "FIPS")
          ),
          [0, "Dimensions"]
        );
      }
      const combinedData = _.reduce(
        dimensionsData,
        (acc, value, key) => {
          acc.push({
            label: key,
            value,
            color: COLORS.purple[valueToColor(value.score)],
            countryValue: _.get(countryDimensionsData, key),
          });
          return acc;
        },
        []
      );
      return _.sortBy(combinedData, (d) => {
        return _.get(d, "label");
      });
    } else {
      let dimensionsData = _.get(data, "country.0.Dimensions");
      const combinedData = _.reduce(
        dimensionsData,
        (acc, value, key) => {
          acc.push({
            label: key,
            value,
            color: COLORS.purple[valueToColor(value.score)],
            countryValue: value,
          });
          return acc;
        },
        []
      );
      return _.sortBy(combinedData, (d) => {
        return _.get(d, "label");
      });
    }
  },
});

export const factorsData$ = selector({
  key: "factorsData",
  get: ({ get }) => {
    const data = get(data$);
    const isStateView = get(isStateView$);
    const filterType = get(filterType$);
    const filterValue = get(filterValue$);

    if (isStateView) {
      let factorsData = _.get(data, "state.state.0.Factors");

      if ("county" === filterType && filterValue) {
        factorsData = _.get(
          _.filter(
            _.get(data, "state.county"),
            (c) => filterValue === normalizeFips(_.get(c, "FIPS"))
          ),
          [0, "Factors"]
        );
      } else if ("msa" === filterType && filterValue) {
        factorsData = _.get(
          _.filter(
            _.get(data, "state.msa"),
            (c) => filterValue === _.get(c, "FIPS")
          ),
          [0, "Factors"]
        );
      }

      return _.reduce(
        factorsData,
        (acc, value, key) => {
          acc.push({
            value: parseFloat(value),
            label: key,
            color: COLORS.lime[valueToColor(value)],
          });
          return acc;
        },
        []
      );
    } else {
      let factorsData = _.get(data, "country.0.Factors");
      return _.reduce(
        factorsData,
        (acc, value, key) => {
          value = value || "1";
          acc.push({
            value: parseFloat(value),
            label: key,
            color: COLORS.lime[valueToColor(value)],
          });
          return acc;
        },
        []
      );
    }
  },
});

export const stateCountyFeatureIds$ = selector({
  key: "stateCountyFeatureIds",
  get: ({ get }) => {
    const data = get(data$);
    const stateFips = normalizeFips(_.get(data, "state.state.0.FIPS"));
    if (stateFips) {
      return adminData.stateCountiesFeatureIds[stateFips] || [];
    }
    return [];
  },
});

export const stateSelectedCountyFeatureIds$ = selector({
  key: "stateSelectedCountyFeatureId",
  get: ({ get }) => {
    const filterType = get(filterType$);
    const filterValue = get(filterValue$);
    if ("county" === filterType && filterValue) {
      return [adminData.countiesFipsToFeatureId[normalizeFips(filterValue)]];
    }
    return [];
  },
});

export const colorScaleToFeatureIds$ = selector({
  key: "colorScaleToFeatureIds",
  get: ({ get }) => {
    const root = get(root$);
    const data = get(data$);
    const isStateView = get(isStateView$);
    if (isStateView) {
      const countyData = _.get(data, "state.county");
      const stateAttr = getStateAttr(root);
      const { focusType, focusValue } =
        _.get(root, [stateAttr, "focused"]) || {};
      return _.reduce(
        countyData,
        (acc, county) => {
          let val, colorScheme;

          if ("factor" === focusType) {
            val = _.get(county, ["Factors", focusValue]);
            colorScheme = "lime";
          } else if ("dimension" === focusType) {
            val = _.get(county, ["Dimensions", focusValue, "score"]);
            colorScheme = "purple";
          } else {
            val = _.get(county, "cwbi score");
            colorScheme = "blue";
          }

          const color = valueToColor(val);
          const fips = normalizeFips(_.get(county, "FIPS"));
          const featureId = parseInt(adminData.countiesFipsToFeatureId[fips]);
          acc["id-" + featureId] = COLORS[colorScheme][color];
          return acc;
        },
        {}
      );
    } else {
      const stateData = _.get(data, "state");
      const stateAttr = getStateAttr(root);
      const { focusType, focusValue } =
        _.get(root, [stateAttr, "focused"]) || {};

      return _.reduce(
        stateData,
        (acc, state) => {
          let val, colorScheme;

          if ("factor" === focusType) {
            val = _.get(state, ["Factors", focusValue]);
            colorScheme = "lime";
          } else if ("dimension" === focusType) {
            val = _.get(state, ["Dimensions", focusValue, "score"]);
            colorScheme = "purple";
          } else {
            val = _.get(state, "cwbi score");
            colorScheme = "blue";
          }

          const color = valueToColor(val);
          const fips = normalizeFips(_.get(state, "FIPS"));
          const featureId = parseInt(adminData.stateFipsToFeatureId[fips]);

          acc["id-" + featureId] = COLORS[colorScheme][color];
          return acc;
        },
        {}
      );
    }
  },
});

export const focused$ = selector({
  key: "focused",
  get: ({ get }) => {
    const root = get(root$);
    const stateAttr = getStateAttr(root);
    return (
      _.get(root, [stateAttr, "focused"]) || {
        focusType: null,
        focusValue: null,
      }
    );
  },
});

export const view$ = selector({
  key: "view",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "view");
  },
});

export const isStateView$ = selector({
  key: "isStateView",
  get: ({ get }) => {
    const root = get(root$);
    const view = _.get(root, "view");
    return "defaultState" === view || "comparingState" === view;
  },
});

export const colorScale$ = selector({
  key: "colorScale",
  get: ({ get }) => {
    const { focusType } = get(focused$);
    let colorScheme = "blue";
    if ("factor" === focusType) {
      colorScheme = "lime";
    } else if ("dimension" === focusType) {
      colorScheme = "purple";
    }
    return [
      { label: "0 - 19", color: COLORS[colorScheme][5] },
      { label: "20 - 39", color: COLORS[colorScheme][4] },
      { label: "40 - 59", color: COLORS[colorScheme][3] },
      { label: "60 - 79", color: COLORS[colorScheme][2] },
      { label: "80 - 100", color: COLORS[colorScheme][1] },
    ];
  },
});

export const filterType$ = selector({
  key: "filterType",
  get: ({ get }) => {
    const root = get(root$);
    const stateAttr = getStateAttr(root);
    return _.get(root, [stateAttr, "filterType"]);
  },
});

export const filterValue$ = selector({
  key: "filterValue",
  get: ({ get }) => {
    const root = get(root$);
    const stateAttr = getStateAttr(root);
    return _.get(root, [stateAttr, "filter"]);
  },
});

export const mouseOverValue$ = selector({
  key: "mouseOverValue",
  get: ({ get }) => {
    const root = get(root$);
    const stateAttr = getStateAttr(root);
    return _.get(root, [stateAttr, "mouseOver"]);
  },
});

export const stateFilterCountiesData$ = selector({
  key: "stateFilterCountiesData",
  get: ({ get }) => {
    const data = get(data$);
    const values = _.get(data, "state.county");
    return _.sortBy(
      _.map(values, (c) => {
        return {
          value: normalizeFips(_.get(c, "FIPS")),
          label: _.get(c, "name"),
        };
      }),
      (v) => {
        return v.label;
      }
    );
  },
});

export const stateFilterMsaData$ = selector({
  key: "stateFilterMsaData",
  get: ({ get }) => {
    const data = get(data$);
    const values = _.get(data, "state.msa");
    return _.sortBy(
      _.map(values, (c) => {
        return {
          value: normalizeFips(_.get(c, "FIPS")),
          label: _.get(c, "name"),
        };
      }),
      (v) => {
        return v.label;
      }
    );
  },
});

export const stateFilterData$ = selector({
  key: "stateFilterData",
  get: ({ get }) => {
    const filterType = get(filterType$);
    if ("county" === filterType) {
      return get(stateFilterCountiesData$);
    } else if ("msa" === filterType) {
      return get(stateFilterMsaData$);
    }
    return [];
  },
});

export const filterValueLabel$ = selector({
  key: "filterValueLabel",
  get: ({ get }) => {
    const filterValue = get(filterValue$);
    const stateFilterData = get(stateFilterData$);
    return _.filter(stateFilterData, (v) => filterValue === v.value)[0];
  },
});

export const stateFilterValueLabel$ = selector({
  key: "stateFilterValueLabel",
  get: ({ get }) => {
    const filterType = get(filterType$) || "county";
    return stateViewByOptions.find(({ value }) => value === filterType);
  },
});

export const countryFilterValueLabel$ = selector({
  key: "countryFilterValueLabel",
  get: ({ get }) => {
    const filterType = get(filterType$);
    return countryViewByOptions.find(({ value }) => value === filterType);
  },
});

export const stateHeaderData$ = selector({
  key: "stateHeaderData",
  get: ({ get }) => {
    const data = get(data$);
    const stateData = _.get(data, "state.state.0");

    return {
      name: _.get(stateData, "name"),
      rank: _.get(stateData, "cwbi rank"),
      score: _.get(stateData, "cwbi score"),
      color: COLORS.blue[valueToColor(_.get(stateData, "cwbi score"))],
    };
  },
});

export const isResetable$ = selector({
  key: "isResetable",
  get: ({ get }) => {
    const root = get(root$);
    const stateAttr = getStateAttr(root);
    let state;
    if (stateAttr === "country") {
      state = _.get(root, "country");
      return state.focused;
    }
    state = _.get(root, stateAttr);
    return state.filterType !== "county" || state.filter || state.focused;
  },
});

export const mapPopupData$ = selector({
  key: "mapPopupData",
  get: ({ get }) => {
    const mouseOverValue = get(mouseOverValue$);
    const filterValue = get(filterValue$);
    const filterType = get(filterType$);
    const value = mouseOverValue || filterValue;
    const isStateView = get(isStateView$);
    const { focusType, focusValue } = get(focused$);
    const data = get(data$);
    const state = _.get(data, "state.state[0].state", "");
    const colorScale = getColorScale(focusType);

    if (value && "county" === filterType) {
      const normalizedFips = normalizeFips(value);
      const countiesByFIPS = get(countiesByFIPS$);
      const county = countiesByFIPS[normalizedFips];
      const focusData = focusType ? _.get(county, [`${_.capitalize(focusType)}s`, focusValue]) : undefined;
      const [latitude, longitude] = countyFipsToGeoPoint[normalizedFips];
      const rank = focusType === "factor" ?  undefined : focusType === "dimension" ?  _.get(focusData, "rankstate") : _.get(county, "cwbi rank state");
      const score = focusType === "factor" ?  focusData : focusType === "dimension" ?  _.get(focusData, "score") :  _.get(county, "cwbi score");
      return {
        state,
        name: `${_.get(county, "name")}  ${state === 'LA' ? 'Parish' : 'County'}`,
        rank,
        score,
        color: COLORS[colorScale][valueToColor(score)],
        dataLabel: focusValue ? focusValue : "Well-Being Index Score",
        latitude,
        longitude,
        areaCount: _.get(data, "state.county", []).length,
      };
    } else if (value && "msa" === filterType) {
      const msas = _.get(data, "state.msa");
      const msaMapData = get(msaMapData$);
      return _.filter(msaMapData, (msa) => value === _.get(msa, "fips"))[0];
    } else if (value && !isStateView) {
      const state = _.filter(
        _.get(data, "state", []),
        (s) => value === normalizeFips(_.get(s, "FIPS"))
      )[0];
      if (state) {
        const { latitude, longitude } = stateToGeoPoint[state.state];

        return {
          latitude: parseFloat(latitude),
          longitude: parseFloat(longitude),
          name: _.get(state, "name"),
          rank: _.get(state, "cwbi rank"),
          score: _.get(state, "cwbi score"),
          color: COLORS[colorScale][valueToColor(_.get(state, "cwbi score"))]
        };
      }
    }
  },
});

export const isCompareStateModalOpen$ = selector({
  key: "isCompareStateModalOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isCompareStateModalOpen");
  },
});

export const isDimensionsModalOpen$ = selector({
  key: "isDimensionsModalOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isDimensionsModalOpen");
  },
});

export const isFactorsModalOpen$ = selector({
  key: "isFactorsModalOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isFactorsModalOpen");
  },
});

export const isCountyModalOpen$ = selector({
  key: "isCountyModalOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isCountyModalOpen");
  },
});

export const isMsaModalOpen$ = selector({
  key: "isMsaModalOpen",
  get: ({ get }) => {
    const root = get(root$);
    return _.get(root, "isMsaModalOpen");
  },
});

export const isCountryView$ = selector({
  key: "isCountryView",
  get: ({ get }) => {
    const root = get(root$);
    return "country" === _.get(root, "view");
  },
});

export const comparableStates$ = selector({
  key: "comparableStates",
  get: ({ get }) => {
    const root = get(root$);
    const defaultState = _.get(root, "defaultState.code");
    return _.filter(stateLabelValues, (s) => s.value !== defaultState);
  },
});

export const hasComparingState$ = selector({
  key: "hasComparingState",
  get: ({ get }) => {
    const root = get(root$);
    return !!_.get(root, "comparingState");
  },
});

export const hasDefaultState$ = selector({
  key: "hasDefaultState",
  get: ({ get }) => {
    const root = get(root$);
    return !!_.get(root, "defaultState");
  },
});

export const comparePanelData$ = selector({
  key: "comparePanelData",
  get: ({ get }) => {
    const root = get(root$);
    const defaultStateCode = _.get(root, "defaultState.code");
    const comparingStateCode = _.get(root, "comparingState.code");
    return {
      defaultStateName: stateCodeToName[defaultStateCode],
      comparingStateName: stateCodeToName[comparingStateCode],
      view: _.get(root, "view"),
    };
  },
});

export const msaMapData$ = selector({
  key: "msaMapData",
  get: ({ get }) => {
    const data = get(data$);
    const { focusType, focusValue } = get(focused$);
    const msas = _.get(data, "state.msa");
    const colorScale = getColorScale(focusType);

    return _.map(msas, (msa) => {
      let score = _.get(msa, "cwbi score");
      let rank = _.get(msa, "cwbi rank state");

      if ("dimension" === focusType) {
        score = _.get(msa, ["Dimensions", focusValue, "score"]);
        rank = _.get(msa, ["Dimensions", focusValue, "rankScore"]);
      } else if ("factor" === focusType) {
        score = _.get(msa, ["Factors", focusValue]);
      }

      const color = COLORS[colorScale][valueToColor(score)];

      return {
        colorScale,
        rank,
        latitude: parseFloat(_.get(msa, "lat")),
        longitude: parseFloat(_.get(msa, "lang") || _.get(msa, "long")),
        name: _.get(msa, "name"),
        fips: _.get(msa, "FIPS"),
        score,
        color,
        dataLabel: focusValue ? focusValue : "Well-Being Index Score",
        areaCount: msas.length
      };
    });
  },
});

export const statesFeatureIds$ = selector({
  key: "statesFeatureIds",
  get: () => {
    return Object.values(adminData.stateFipsToFeatureId);
  },
});

export const selectedStateFeatureIds$ = selector({
  key: "selectedStateFeatureIds",
  get: ({ get }) => {
    return [];
  },
});

export const isStateRankView$ = selector({
  key: "isStateRankView",
  get: ({ get }) => {
    const isStateView = get(isStateView$);
    const filterType = get(filterType$);
    return !isStateView && "rankings" === filterType;
  },
});

export const stateRanking$ = selector({
  key: "stateRanking",
  get: ({ get }) => {
    const data = get(data$);
    const isStateRankView = get(isStateRankView$);
    if (!isStateRankView) {
      return [];
    }

    const stateData = _.map(_.get(data, "state"), (s) => {
      const score = parseInt(_.get(s, "cwbi score"));
      return {
        rank: parseInt(_.get(s, "cwbi rank state")),
        name: _.get(s, "name"),
        code: _.get(s, "state"),
        score: score,
        color: COLORS.blue[valueToColor(score)],
      };
    });
    return _.sortBy(stateData, (s) => s.rank);
  },
});

export const pipMapsData$ = selector({
  key: "pipMapsData",
  get: ({ get }) => {
    const data = get(countryData$);
    const root = get(root$);
    const view = _.get(root, "view");
    const defaultState = _.get(root, "defaultState.code");
    const comparingState = _.get(root, "comparingState.code");
    const { focusType, focusValue } = _.get(root, [view, "focused"]) || {};

    let colorScale = "blue";
    if ("dimension" === focusType) {
      colorScale = "purple";
    } else if ("factor" === focusType) {
      colorScale = "lime";
    }
    let states = [];
    if ("country" === view) {
      states = ["AK", "HI"];
    } else if ("comparingState" === view) {
      states = [defaultState];
    } else if ("defaultState" === view && comparingState) {
      states = [comparingState];
    }

    const statesData = _.reduce(
      _.get(data, "state"),
      (acc, v) => {
        acc[v.state] = v;
        return acc;
      },
      {}
    );

    return _.map(states, (s) => {
      const stateData = _.get(statesData, s);
      const score = _.get(stateData, "cwbi score");
      let value = score;

      if ("dimension" === focusType) {
        value = _.get(stateData, ["Dimensions", focusValue, "score"]);
      } else if ("factor" === focusType) {
        value = _.get(stateData, ["Factors", focusValue]);
      }

      const color = COLORS[colorScale][valueToColor(value)];

      return {
        name: stateCodeToName[s],
        bbox: STATES_BBOXES[s],
        code: s,
        color,
        featureIds: [
          adminData.stateFipsToFeatureId[
            normalizeFips(_.get(stateData, "FIPS"))
          ],
        ],
      };
    });
  },
});

export const defaultStateName$ = selector({
  key: "defaultStateName",
  get: ({ get }) => {
    const root = get(root$);
    const stateCode = _.get(root, "defaultState.code");
    return stateCodeToName[stateCode];
  },
});

// Gets all the possible data needed from
// the current state for analytics events
export const analyticsData$ = selector({
  key: "analyticsData",
  get: ({ get }) => {
    const root = get(root$);
    const counties = get(stateFilterCountiesData$);

    let filterType = "";

    if (root.view === "country") {
      if (_.get(root, "country.focused.focusType")) {
        filterType = _.get(root, "country.focused.focusType");
      } else {
        filterType = _.get(root, "country.filterType");
      }
    } else {
      if (_.get(root, "defaultState.focused.focusType")) {
        filterType = _.get(root, "defaultState.focused.focusType");
      } else {
        filterType = _.get(root, "defaultState.filterType");
      }
    }

    const stateCode = _.get(root, "defaultState.code");
    const stateName = stateCodeToName[stateCode];

    const countyFIPS = _.get(root, "defaultState.filter");
    const countyValue = counties.find((county) => county.value === countyFIPS);

    const data = {
      filterType,
      filters: [],
      state: root.view === "country" ? "" : stateName,
      county: root.view === "country" || !countyValue ? "" : countyValue.label,
    };

    return data;
  },
});

const ACTIONS = {
  setView(root, view) {
    root.view = view;
  },
  setState(root, fips) {
    const stateAttr = getStateAttr(root);
    const state = _.get(root, stateAttr);

    state.code = fips;
    delete state.filter;
  },
  toggleSidebar(root) {
    root.isSidebarOpen = !root.isSidebarOpen;
  },
  setFocused(root, focusType, focusValue) {
    let nextVal = { focusType, focusValue };
    const stateAttr = getStateAttr(root);
    const current = _.get(root, [stateAttr, "focused"]) || {
      focusType: null,
      focusValue: null,
    };

    if (focusType === current.focusType && focusValue === current.focusValue) {
      nextVal = null;
    }

    _.set(root, [stateAttr, "focused"], nextVal);
    this.closeModals(root);
  },
  setFilterType(root, value) {
    const stateAttr = getStateAttr(root);
    const state = _.get(root, stateAttr) || {};
    const currentFilterType = _.get(state, "filterType");
    _.set(root, "isCountyModalOpen", false);
    _.set(root, "isMsaModalOpen", false);
    state.filterType = value;
    if (currentFilterType !== value) {
      delete state.filter;
    }
  },

  setFilterValue(root, value) {
    const stateAttr = getStateAttr(root);
    _.set(root, [stateAttr, "filter"], value);
    this.closeModals(root);
  },

  setMouseOverValue(root, value) {
    const stateAttr = getStateAttr(root);
    const current = _.get(root, [stateAttr, "mouseOver"]);
    if (current !== value) {
      _.set(root, [stateAttr, "mouseOver"], value);
    }
  },
  setFilterValueFromFeatureId(root, featureId) {
    const view = _.get(root, "view");
    const isCountryView = view === "country";
    let fips;
    if (isCountryView) {
      if (featureId) {
        fips = adminData.stateFeatureIdToFips[featureId];
        const state = FIPS_TO_STATE[fips];
        this.selectStateFromCountryView(root, state);
      }
    } else {
      fips = adminData.countiesFeatureIdToFips[featureId];
      const stateAttr = getStateAttr(root);
      _.update(root, [stateAttr, "filter"], (current) => {
        return current === fips ? null : fips;
      });
    }
  },
  setMouseOverValueFromFeatureId(root, featureId) {
    const view = _.get(root, "view");
    const fips =
      view === "country"
        ? adminData.stateFeatureIdToFips[featureId]
        : adminData.countiesFeatureIdToFips[featureId];
    const stateAttr = getStateAttr(root);
    const current = _.get(root, [stateAttr, "mouseOver"]);
    if (current !== fips) {
      _.set(root, [stateAttr, "mouseOver"], fips);
    }
  },

  commitMouseOverValue(root) {
    const stateAttr = getStateAttr(root);
    const fips = _.get(root, [stateAttr, "mouseOver"]);
    const view = _.get(root, "view");
    const isCountryView = view === "country";
    if (isCountryView) {
      const state = FIPS_TO_STATE[fips];
      this.selectStateFromCountryView(root, state);
    } else {
      _.update(root, [stateAttr, "filter"], (current) => {
        return current === fips ? null : fips;
      });
    }
  },

  reset(root) {
    const stateAttr = getStateAttr(root);
    let state;
    if (stateAttr === "country") {
      state = _.get(root, "country", {});
      delete state.focused;
    } else {
      state = _.get(root, stateAttr);
      delete state.focused;
      delete state.filter;
      state.filterType = "county";
    }
  },
  openCompareStateModal(root) {
    _.set(root, "isCompareStateModalOpen", true);
  },
  closeCompareStateModal(root) {
    _.set(root, "isCompareStateModalOpen", false);
  },
  exitCompareState(root) {
    delete root.comparingState;
    _.set(root, "view", "defaultState");
  },
  compareState(root, code) {
    const defaultState = _.get(root, "defaultState");
    const compareState = makeDefaultStateState(code);
    if (defaultState.focused) {
      compareState.focused = { ...defaultState.focused };
    }
    _.set(root, "isCompareStateModalOpen", false);
    _.set(root, "comparingState", compareState);
    _.set(root, "view", "comparingState");
  },
  setCountryView(root) {
    _.set(root, "view", "country");
    _.set(root, "isFactorsModalOpen", false);
    _.set(root, "isDimensionsModalOpen", false);
    _.set(root, "comparingState", null);
  },
  selectStateFromCountryView(root, code) {
    delete root.comparingState;
    _.set(root, "defaultState", makeDefaultStateState(code));
    _.set(root, "view", "defaultState");
  },
  /* Functions for mobile modals */
  openDimensionsModal(root) {
    this.closeModals(root);
    _.set(root, "isDimensionsModalOpen", true);
  },
  closeDimensionsModal(root) {
    _.set(root, "isDimensionsModalOpen", false);
  },
  openFactorsModal(root) {
    this.closeModals(root);
    _.set(root, "isFactorsModalOpen", true);
  },
  closeFactorsModal(root) {
    _.set(root, "isFactorsModalOpen", false);
  },
  openCountyModal(root) {
    this.setFilterType(root, "county");
    _.set(root, "isFactorsModalOpen", false);
    _.set(root, "isDimensionsModalOpen", false);
    _.set(root, "isMsaModalOpen", false);
    _.set(root, "isCountyModalOpen", true);
  },
  closeCountyModal(root) {
    _.set(root, "isCountyModalOpen", false);
  },
  openMsaModal(root) {
    this.setFilterType(root, "msa");
    _.set(root, "isFactorsModalOpen", false);
    _.set(root, "isDimensionsModalOpen", false);
    _.set(root, "isCountyModalOpen", false);
    _.set(root, "isMsaModalOpen", true);
  },
  closeMsaModal(root) {
    _.set(root, "isMsaModalOpen", false);
  },

  closeModals(root) {
    _.set(root, "isFactorsModalOpen", false);
    _.set(root, "isDimensionsModalOpen", false);
    _.set(root, "isCompareStateModalOpen", false);
    _.set(root, "isMsaModalOpen", false);
    _.set(root, "isCountyModalOpen", false);
  },
  closeModalsAndSetDefaultStateView(root) {
    this.closeModals(root);
    _.set(root, "view", "defaultState");
  },
  closeModalsAndSetComparingStateView(root) {
    this.closeModals(root);
    _.set(root, "view", "comparingState");
  },
  closeModalsAndCompare(root) {
    this.closeModals(root);
    delete root.comparingState;
    _.set(root, "view", "defaultState");
  },
  openCountryRankingView(root) {
    _.set(root, "view", "country");
    _.set(root, "country.filterType", "rankings");
  },
};

export const stateViewByOptions = [
  { value: "county", label: "View: County" },
  { value: "msa", label: "View: City/Metro" },
];
export const countryViewByOptions = [
  { value: "rankings", label: "View: Rankings" },
  { value: "states", label: "View: States" },
];
