import SelectIcon, {selectStyles, DropdownIndicator} from "./Select";
import Select from "react-select";
import {
  stateFilterData$,
  filterValueLabel$,
  filterType$,
  stateFilterValueLabel$,
  useRootState,
  isResetable$,
  stateViewByOptions
} from "../state.js";
import { useRecoilValue } from "recoil";
import _ from "lodash";
import { ReactComponent as ResetIcon } from "./icons/Reset.svg";
import { useAnalytics } from "../analytics/analytics";

function FilterState() {
  const [root, actions] = useRootState();
  const stateFilterData = useRecoilValue(stateFilterData$);
  const filterValueLabel = useRecoilValue(filterValueLabel$);
  const filterType = useRecoilValue(filterType$);
  const stateFilterValueLabel = useRecoilValue(stateFilterValueLabel$);
  const isResetable = useRecoilValue(isResetable$);
  const { logClickEvent } = useAnalytics();

  return (
    <div>
      <div className="flex flex-row items-center justify-between">
          <div className="flex w-5/6">
            <div className="w-1/2 pr-1">
              <Select
                  styles={{...selectStyles, valueContainer: (provided) => ({ ...provided, margin: 0})}}
                  options={stateViewByOptions}
                  value={stateFilterValueLabel}
                  isClearable={false}
                  onChange={({value}) => {
                    actions.setFilterType(value)
                    logClickEvent({ filterType: value });
                  }}
                  components={{
                      DropdownIndicator,
                      IndicatorSeparator: () => null
                  }}
              />
            </div>
            <div className="w-1/2">
              <SelectIcon
                options={stateFilterData}
                placeholder={"msa" === filterType ? "All Cities" : "All Counties"}
                value={filterValueLabel || ""}
                isClearable={false}
                onChange={(value) => {
                  actions.setFilterValue(_.get(value, "value"));
                  logClickEvent({ county: value.label });
                }}
              />
            </div>
          </div>
          <div className="w-1/6 ml-4">
              <button
                  onClick={actions.reset}
                  style={{whiteSpace: "nowrap", width: "100%", borderWidth: "2px", borderRadius: "10px"}}
                  className={`p-2 text-base font-medium transition-all duration-500 ease-out text-medium focus:outline-none ${isResetable ? "border border-medium" : "cursor-not-allowed"}`}
              >
                  <ResetIcon className={`${isResetable ? "text-scale-1" : "text-low"} inline-block mr-1`}/> Reset
              </button>
          </div>
      </div>
    </div>
  );
}

export default FilterState;